import React from 'react';
import {
    slugBlockfiRates,
    slugVoyagerRates,
    slugGeminiRates,
    slugCelsiusRates,
    slugHodlnautRates,
    slugLednRates,
    slugCryptoComRates,
    slugNexoRates,
    slugVauldRates,
    slugHaruRates,
    slugAbraRates,
    slugFinbloxRates,
    slugToSymbolMap,
    symbolToCurrencyMap,
} from '../utils/constants';

import { hodlnautRates, hodlnautInfoUS } from './hodlnaut-info';
import { haruRates, haruMaxRateInfo, haruInfoUS } from './haru-info';
import { celsiusRates, celsiusRowInfoUS, celsiusMaxRateInfoUS, celsiusMaxRateInfo } from './celsius-info';
import { nexoRates, nexoRowInfoInt } from './nexo-info';
import { vauldRates, vauldRowInfoUS, vauldMaxRateInfo } from './vauld-info';
import { blockfiRates, blockfiRowInfoUS } from './blockfi-info';
import { geminiRates, geminiRowInfoUS, geminiAdditionalInfoInt } from './gemini-info';
import { voyagerRates, voyagerRowInfoUS, voyagerAddtionalInfoMap } from './voyager-info';
import { abraRates, abraMaxRateInfo, abraRowInfoUS, abraRowInfoInt } from './abra-info';
import { cryptoComRates, makeCryptoComMaxRateInfo, cryptoComRowInfoUS, cryptoComAdditionalInfoInt } from './crypto-com-info';
import { lednRates, lednInfoInt } from './ledn-info';
import { finbloxRates, finbloxRowInfoInt } from './finblox-info';

import { pathnameToCurrencyUSMap, pathnameToCurrencyIntMap, specifyAPR, emptyCell } from './tableInfo';

export const slugToRatesMap = {
    [slugBlockfiRates]: blockfiRates,
    [slugVoyagerRates]: voyagerRates,
    [slugGeminiRates]: geminiRates,
    [slugCelsiusRates]: celsiusRates,
    [slugHodlnautRates]: hodlnautRates,
    [slugLednRates]: lednRates,
    [slugCryptoComRates]: cryptoComRates,
    [slugNexoRates]: nexoRates,
    [slugVauldRates]: vauldRates,
    [slugHaruRates]: haruRates,
    [slugAbraRates]: abraRates,
    [slugFinbloxRates]: finbloxRates,
};

export const createRatesSorter = (keyToSort) => (a, b) => {
    const AmaxAPY = parseFloat(a[keyToSort]?.props?.children[0] || a[keyToSort]);
    const BmaxAPY = parseFloat(b[keyToSort]?.props?.children[0] || b[keyToSort]);
    if (AmaxAPY > BmaxAPY) {
        return -1;
    } else if (AmaxAPY < BmaxAPY) {
        return 1;
    } else {
        return 0;
    }
};

const createSortedRates = (international) => {
    const tempArray = [];
    let map = pathnameToCurrencyUSMap;
    if (international) {
        map = pathnameToCurrencyIntMap;
    }
    
    Object.entries(map).forEach(([key, rates]) => {
        rates.forEach((rate) => {
            if (rate.name === 'Vauld' || rate.name === 'Celsius' || rate.name === 'Hodlnaut' || rate.name === 'BlockFi' || rate.name === 'Gemini Earn' || rate.name === 'Haru Invest' || rate.name === 'Abra') {
                return;
            }
            tempArray.push({...rate, symbol: slugToSymbolMap[key].symbol});
        });
    });

    const slugToRatesMapExclusive = { ...slugToRatesMap };
    delete slugToRatesMapExclusive[slugVoyagerRates];
    delete slugToRatesMapExclusive[slugVauldRates];
    delete slugToRatesMapExclusive[slugCelsiusRates];
    delete slugToRatesMapExclusive[slugHodlnautRates];
    delete slugToRatesMapExclusive[slugBlockfiRates];
    delete slugToRatesMapExclusive[slugGeminiRates];
    delete slugToRatesMapExclusive[slugAbraRates];

    Object.values(slugToRatesMapExclusive).forEach(({ newUS, newInt, key }) => {
        let rates = newUS;
        if (international) {
            rates = newInt;
        }

        Object.entries(rates).forEach(([symbol, ratesObject]) => {
            if (!ratesObject.max) {
                return;
            }

            if (!symbolToCurrencyMap[symbol].slug) {
                let baseAPY = ratesObject.base;
                let maxAPY = ratesObject.max;
                let rowInfo;
                let additionalInfo;
                switch (key) {
                    case 'celsius':
                        rowInfo = celsiusRowInfoUS;
                        maxAPY += celsiusMaxRateInfoUS;
                        break;
                    case 'vauld':
                        rowInfo = vauldRowInfoUS;
                        maxAPY += vauldMaxRateInfo;
                        break;
                    case 'cryptoCom':
                        baseAPY += specifyAPR;
                        maxAPY = makeCryptoComMaxRateInfo(ratesObject.max);
                        rowInfo = cryptoComRowInfoUS;
                        break;
                    case 'haru':
                        rowInfo = haruInfoUS;
                        baseAPY += specifyAPR;
                        maxAPY += haruMaxRateInfo;
                        break;
                    case 'abra':
                        rowInfo = abraRowInfoUS;
                        break;
                    case 'voyager':
                        rowInfo = voyagerRowInfoUS;
                        baseAPY += specifyAPR;
                        maxAPY += specifyAPR;
                        additionalInfo = voyagerAddtionalInfoMap[symbol];
                        break;
                    case 'blockfi':
                        rowInfo = blockfiRowInfoUS;
                        break;
                    case 'gemini':
                        rowInfo = geminiRowInfoUS;
                        break;
                    case 'hodlnaut':
                        rowInfo = hodlnautInfoUS;
                        break;
                }
                if (international) {
                    switch (key) {
                        case 'celsius':
                            additionalInfo = emptyCell;
                            maxAPY = ratesObject.max + celsiusMaxRateInfo;
                            break;
                        case 'cryptoCom':
                            additionalInfo = cryptoComAdditionalInfoInt;
                            break;
                        case 'abra':
                            rowInfo = { ...abraRowInfoUS, ...abraRowInfoInt };
                            break;
                        case 'blockfi':
                            additionalInfo = emptyCell;
                            break;
                        case 'gemini':
                            additionalInfo = geminiAdditionalInfoInt;
                            break;
                        case 'ledn':
                            rowInfo = lednInfoInt;
                            break;
                        case 'nexo':
                            rowInfo = nexoRowInfoInt;
                            break;
                        case 'finblox':
                            rowInfo = finbloxRowInfoInt;
                            break;
                    }
                }

                additionalInfo = additionalInfo ||  rowInfo.additionalInfo;
                tempArray.push({ ...rowInfo, baseAPY, maxAPY, symbol, additionalInfo });
            }
        });
    });
    return tempArray.sort(createRatesSorter('maxAPY'));
};

export const allRatesUS = createSortedRates();
export const stabelcoinRatesUS = [];
export const nonStabelcoinRatesUS = [];
allRatesUS.forEach((rate) => {
    if (symbolToCurrencyMap[rate.symbol].isStablecoin) {
        stabelcoinRatesUS.push(rate);
    } else {
        nonStabelcoinRatesUS.push(rate);
    }
});

export const allRatesInt = createSortedRates(true);
export const stabelcoinRatesInt = [];
export const nonStabelcoinRatesInt = [];
allRatesInt.forEach((rate) => {
    if (symbolToCurrencyMap[rate.symbol].isStablecoin) {
        stabelcoinRatesInt.push(rate);
    } else {
        nonStabelcoinRatesInt.push(rate);
    }
});






// const seePopularCoins = () => {
//     const arr = Object.values(slugToRatesMap);
//     const symbols = {};
//     arr.forEach((rate) => {
//         const keys = Object.keys(rate.newUS);
//         keys.forEach((key) => {
//             if (symbols[key]) {
//                 symbols[key] += 1;
//                 // symbols[key].push(rate.platform);
//             } else {
//                 symbols[key] = 1;
//                 // symbols[key] = [rate.platform];
//             }
//         });
//     });
//     return Object.entries(symbols).sort(([,a],[,b]) => b-a);
//     // return JSON.stringify(Object.entries(symbols).sort(([,a],[,b]) => b-a));
// };
// console.log('seePopularCoins', seePopularCoins());
