import React from 'react'
import { TickIcon, Table, TabNavigation, Tab, CrossIcon, Text, Pane, Paragraph, ArrowsHorizontalIcon } from 'evergreen-ui';
import { Link, InfoPopover } from '.';


// export const inKindPopover = (
//     <Paragraph>
//         <b>Paid in kind</b> means you will be paid interest in the currency you deposit. So if you deposit BTC you will receive interest in BTC.
//     </Paragraph>
// );

const HardwareTable = ({ wallets }) => {

    return (
        <>
            <Pane paddingY={12} className="platforms-table-container">
                <Table minWidth={870} className="platforms-table">
                    <Table.Head height="auto" className="platforms-table-header">
                        <Table.TextHeaderCell paddingY={12} flexBasis={66} className="platforms-table-left-column"  borderTopLeftRadius={4}>
                            Wallet
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12}>
                            Price
                            {/* <InfoPopover content={inKindPopover} /> */}
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12}>Coins Supported</Table.TextHeaderCell>
                        
                        <Table.TextHeaderCell paddingY={12}>Open-source</Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12}>Touchscreen</Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12}>Bluetooth</Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12}>Promo Bonus</Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body overflowY="initial">
                        {wallets.map(({
                            walletName,
                            url,
                            price,
                            coins,
                            openSource,
                            touchscreen,
                            bluetooth,
                            bonusText,
                            bonusLink,
                            logoPath,
                            coinsLink,
                        }) => {
                            return (
                                <Table.Row isSelectable height="auto">
                                    <Table.TextCell paddingY={12} flexBasis={66} className="platforms-table-left-column">
                                        <Pane display="flex" alignItems="center" >
                                            <img src={logoPath} alt="loading" width={24} />
                                            <a href={url} target="_blank" rel="noopener nofollow">{walletName}</a>
                                        </Pane>
                                    </Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text">${price}</Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text">
                                        {coinsLink ? <a href={coinsLink} target="_blank">{coins}</a> : coins}
                                    </Table.TextCell>
                                    <Table.TextCell paddingY={12}>
                                        {openSource ? <TickIcon color="success" /> : <CrossIcon color="danger" />}
                                    </Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text">
                                        {touchscreen ? <TickIcon color="success" /> : <CrossIcon color="danger" />}
                                    </Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text">
                                        {bluetooth || <CrossIcon color="danger" />}
                                    </Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text">
                                        {bonusLink ? <Link to={bonusLink}>{bonusText}</Link> : '-'}
                                    </Table.TextCell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Pane>
            <Pane className="platforms-table-mobile">
                <ArrowsHorizontalIcon /> <Text size={300}>Table scrolls horizontally</Text>
            </Pane>
        </>
     );
}
 
export default HardwareTable;