import React from 'react'
import { VAULD_BONUS_HTML } from '../utils/constants';
import { vauld21Apr22RatesUS, vauld21Apr22RatesInt, vauldDateEffective } from './vauld-rates/4-21-22';
import { vauld7Apr22RatesUS, vauld7Apr22RatesInt } from './vauld-rates/4-7-22';

const emptyCell = '-';
const vauldPaused = <span>Vauld has suspended all trading, deposits, and withdrawals, effective July 4, 2022; <a target="_blank" rel="noopener" href="https://www.vauld.com/blog/corporate-statement/">Vauld Announcement</a></span>;

export const vauldRowInfoUS = {
    name: 'Vauld',
    withdrawalFees: vauldPaused,
    additionalInfo: emptyCell,
    icon: '/images/vauld-logo.svg',
    order: 5.5,
    // bonus: 'activity bonuses',
    bonusLink: 'vauld-bonus',
    referralSlug: 'vauld-referral',
};

export const vauldMaxRateInfo = ' - must lock asset for 1 month';

export const vauldRates = {
    oldUS: vauld7Apr22RatesUS,
    oldInt: vauld7Apr22RatesInt,
    newUS: vauld21Apr22RatesUS,
    newInt: vauld21Apr22RatesInt,
    platform: 'Vauld',
    // bonusHTML: VAULD_BONUS_HTML,
    dateEffectiveUS: vauldDateEffective,
    dateEffectiveInt: vauldDateEffective,
    maxInfoInt: 'the max APY of each coin (except SKL and SOL) requires locking asset for 1 month',
    maxInfoUS: 'the max APY of each coin (except SKL and SOL) requires locking asset for 1 month',
    key: 'vauld',
    availabilityInfoUS: vauldPaused,
    availabilityInfoInt: vauldPaused,
};
