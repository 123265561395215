import React from 'react'
import { navigate } from 'gatsby';
import { TagInput, TextInput, Text, Pane } from 'evergreen-ui';
import hardwareWalletsInfo from '../../data/hardwareWalletsInfo.json';
const { walletNameCombinations } = require('../../utils/createHardwareWalletPages');

// const allValues = ['Trezor Model T', 'Ledger Nano X', 'Trezor Safe 3', 'Ledger Nano S Plus', 'Trezor Model One', 'Blockstream Jade'];
   
const AutoCompleteTagInput = ({ wallets, setWallets}) => {
    const walletArr = wallets.map(({ walletName }) => walletName)
    const onChange = (newArray) => {
        const filteredWallets = hardwareWalletsInfo.filter(wallet => newArray.includes(wallet.walletName));
        const matchingItem = walletNameCombinations.find(({ title }) => filteredWallets.every(wallet => {
            return title.includes(wallet.walletName);
        }))
        if (!matchingItem) {
            navigate('/best-hardware-wallets/');
        } else {
            navigate('/' + matchingItem.slug + '/');
        }
        // setWallets(filteredWallets);
    }

//   const allValues = React.useMemo(
//     () => ['Trezor Model T', 'Ledger Nano X', 'Trezor Safe 3', 'Ledger Nano S Plus', 'Trezor Model One', 'Blockstream Jade'],
//     []
//   )
    let isRemovable = true;
    const autocompleteItems = hardwareWalletsInfo.filter((i) => !walletArr.includes(i.walletName)).map(({ walletName }) => walletName)
    if (walletArr.length < 3) {
        isRemovable = false;
    }
    
    // return <TextInput name="text-input-name" placeholder="Text input placeholder..." />
    
    return (
        <Pane paddingY={12}>
            <Text display="block" marginBottom={8} color="rgb(16, 24, 64)" fontWeight={500}>
                Wallets to Compare{!isRemovable && ' (Minimum of 2)'}:
            </Text>
            <TagInput
                inputProps={{ width: 0 }}
                // popoverMinWidth={800}
                values={walletArr}
                onChange={onChange}
                autocompleteItems={autocompleteItems}
                tagProps={{
                    isRemovable,
                }}
            />
        </Pane>
    )
}
 
export default AutoCompleteTagInput;