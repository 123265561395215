import { useState, useEffect } from 'react';

const useQueryParams = () => {
    const [windowObj, updateWindow] = useState('');
    useEffect(() => {
        updateWindow(window);
    }, []);

    return [windowObj?.location?.search || ''];
};

export default useQueryParams;