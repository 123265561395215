import React from 'react';
import { SelectField } from 'evergreen-ui';

const CustomSelect = ({ value, setValue, options, label, ...rest }) => {
    return (
        <SelectField label={label} value={value} onChange={event => setValue(event.target.value)} {...rest}>
            {options.map(({ val, text }) => <option value={val} selected={val === value}>{text}</option>)}
        </SelectField>
    )
}

export default CustomSelect;
