import React from 'react';
import { Table, Text, Pane } from 'evergreen-ui';

const DeveloperActivityTable = ({ total, itemsPerYear, columnTitle }) => {
    return (
        <Pane width={250} marginX="auto" marginBottom="1.5em">
            <Table>
                <Table.Head position="sticky" top={-1}>
                    <Table.TextHeaderCell flex={1}>Year</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={2}>{columnTitle}</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {itemsPerYear.sort((a, b) => b['year'] - a['year']).map(({ yearCount, year }, index) => (
                        <Table.Row isSelectable height="auto" paddingY={12} borderBottomColor={((itemsPerYear.length - 1) === index) ? "#c1c4d6" : null}>
                            <Table.TextCell flex={1}>{year}</Table.TextCell>
                            <Table.TextCell flex={2}>{yearCount.toLocaleString()}</Table.TextCell>
                        </Table.Row>
                    ))}
                    <Table.Row isSelectable height="auto" paddingY={12} >
                        <Table.TextCell flex={1}>Total</Table.TextCell>
                        <Table.TextCell flex={2}>{total.toLocaleString()}</Table.TextCell>
                    </Table.Row>
                </Table.Body>
            </Table>
            {/* <Text size={300}>* First year of activity</Text> */}
        </Pane>
    );
}

export default DeveloperActivityTable;
