import React from 'react';
import { Menu } from 'evergreen-ui';
import { highestRates } from '../../utils/constants';
import { NavDropdownLink, DropdownNav } from '.';

const HighestRatesNav = () => {
    const popoverContent = (
        <Menu>
            <Menu.Group>
                {highestRates.map(({ name, slug, imageUrl }) => (
                    <NavDropdownLink slug={slug} imageUrl={imageUrl} label={name} />
                ))}
            </Menu.Group>
        </Menu>
    );

    return <DropdownNav popoverContent={popoverContent}>Highest Rates</DropdownNav>;
};

export default HighestRatesNav;
