import React from 'react';
import { ratesUS as newUS, ratesInt as newInt, hodlnautDateEffective } from './hodlnaut-rates/8-1-22';
import { ratesUS as oldUS, ratesInt as oldInt } from './hodlnaut-rates/7-8-22';

const emptyCell = '-';
const freeWithdrawal = 'but 1 free crypto withdrawal per month';
const hodlnautHalted = <span>Hodlnaut has halted withdrawals, token swaps, and deposits, effective August 8, 2022; <a target="_blank" rel="noopener" href="https://www.hodlnaut.com/press/hodlnaut-message-to-our-users">Hodlnaut Announcement</a></span>;

const usdcWithdraw = <div>10 USDC (ERC-20)<br />1 USDC (BEP-20)</div>;

export const hodlnautWithdrawalsRaw = {
    BTC: '0.0004 BTC',
    ETH: '0.0036 ETH',
    USDT: '10 USDT',
    USDC: usdcWithdraw,
    DAI: '10 DAI',
    WBTC: '0.0004 WBTC',
    PAXG: '0.01 PAXG',
};

export const hodlnautWithdrawals = {};
for (let key in hodlnautWithdrawalsRaw) {
    const value = hodlnautWithdrawalsRaw[key];
    if (typeof value === 'string') {
        hodlnautWithdrawals[key] = `${value}, ${freeWithdrawal}`;
    } else {
        hodlnautWithdrawals[key] = [value, freeWithdrawal];
    }
}

export const hodlnautInfoUS = {
    name: 'Hodlnaut',
    additionalInfo: emptyCell,
    icon: '/images/hodlnaut-logo.svg',
    order: 2,
    bonus: '$30 in USDC',
    bonusLink: 'hodlnaut-bonus',
    referralSlug: 'hodlnaut-referral',
};

export const hodlnautRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Hodlnaut',
    // bonusHTML: HODLNAUT_BONUS_HTML,
    dateEffectiveUS: hodlnautDateEffective,
    dateEffectiveInt: hodlnautDateEffective,
    key: 'hodlnaut',
    availabilityInfoUS: hodlnautHalted,
    availabilityInfoInt: hodlnautHalted,
};
