import React, { useState } from 'react';
import { Link } from '.';
import { slugToRatesMap } from '../../data/ratesTableInfo';
import { symbolToCurrencyMap } from '../../utils/constants';
import useQueryParams from '../../utils/useQueryParams';
import useLocation from '../../utils/useLocation';
import useHideContent from '../../utils/useHideContent';
import { LocationControl } from '.';
import InfoPopover from './InfoPopover';
import { baseAPYPopover, inKindPopover, maxAPYPopover } from './PlatformsTableHeader';
import { Strong, Alert, Table, TabNavigation, Tab, Checkbox, Text, Pane, ArrowsHorizontalIcon } from 'evergreen-ui';

const getRatesType = (pathname, dateEffective) => {
    if (pathname.includes('stablecoins')) {
        return `No changes to stablecoins for ${dateEffective}`;
    }

    return `No cryptocurrency changes for ${dateEffective} (non-stablecoins)`;
}

const RatesTable = ({ aprOrApy, rates, ratesSubdirectory, pathname }) => {
    const [hideContent] = useHideContent();
    const tabsArray = [
        { label: 'All', path: ratesSubdirectory },
        { label: 'Stablecoins', path: ratesSubdirectory + 'stablecoins/' },
        { label: 'Cryptocurrencies', path: ratesSubdirectory + 'cryptocurrencies/' },
    ];
    const combined = (
        <>
            {rates.APR ? inKindPopover : baseAPYPopover}
            <br/>
            {maxAPYPopover}
        </>
    );

    const [showChanged, updateShowChanged] = useState();
    let areChangedRows = false;
    const [search] = useQueryParams();
    const [location, onChange] = useLocation();
    let newRates;
    let oldRates;
    let maxInfo;
    let dateEffective;
    let availabilityInfo;
    let notAvailableText = `${rates.platform} is not available for earning interest in the United States`;
    if (location === 'international') {
        newRates = rates.newInt;
        oldRates = rates.oldInt;
        maxInfo = rates.maxInfoInt;
        dateEffective = rates.dateEffectiveInt;
        availabilityInfo = rates.availabilityInfoInt;
        notAvailableText = `${rates.platform} is ONLY available in the United States`;
    } else {
        newRates = rates.newUS;
        oldRates = rates.oldUS;
        maxInfo = rates.maxInfoUS;
        dateEffective = rates.dateEffectiveUS;
        availabilityInfo = rates.availabilityInfoUS;
    }
    const currenciesArray = Object.keys(newRates).sort();
    const unchangedClass = showChanged ? 'hidden-row' : '';

    return (
        <div className={hideContent}>
            <LocationControl location={location} onChange={onChange} />
            {currenciesArray.length === 0
                ? <Alert marginTop="2rem" marginBottom="3rem" intent="warning" title={notAvailableText}></Alert>
                // ? <InlineAlert size={102} marginBottom={32}>{rates.platform} is not available outside the US</InlineAlert>
                : (
                <Pane marginBottom="1.5em">
                    {availabilityInfo && <Alert marginTop="2rem" intent="warning" title={availabilityInfo}></Alert>}
                    <TabNavigation paddingY={12}>
                        {tabsArray.map(({ label, path }) => (
                            <Tab appearance="primary" key={label} is="a" href={path + search} id={label} isSelected={pathname === path}>
                                {label}
                            </Tab>
                        ))}
                    </TabNavigation>
                    {/* <Checkbox checked={showChanged} label="Only show coins with a rate change (changes are highlighted)" onChange={e => updateShowChanged(e.target.checked)} /> */}
                    <Checkbox checked={showChanged} label={<Text size={300}>Only show coins with a rate change (highlighted in <Text background="#FFEFD2" size={300}>&nbsp;&nbsp;&nbsp;&nbsp;</Text>)</Text>} onChange={e => updateShowChanged(e.target.checked)} />
                    <Pane className="rates-table-mobile">
                        <ArrowsHorizontalIcon /> <Text size={300}>Table scrolls horizontally</Text>
                    </Pane>
                    <Pane paddingY={12} className="rates-table-container">
                        <Table minWidth={700} className="rates-table">
                            <Table.Head height="auto" className="rates-table-header">
                                <Table.TextHeaderCell paddingY={12} className="rates-table-left-column" flex={0.9} borderTopLeftRadius={4}>
                                    Currency
                                </Table.TextHeaderCell>
                                <Table.TextHeaderCell paddingY={12} flex={2}>Current Rates (effective {dateEffective}){maxInfo && ' *'}</Table.TextHeaderCell>
                                <Table.TextHeaderCell paddingY={12} flex={2}>Previous Rates{maxInfo && ' *'}</Table.TextHeaderCell>
                                <Table.TextHeaderCell paddingY={12} flex={2}>Base or Max {aprOrApy} <InfoPopover content={combined} /></Table.TextHeaderCell>
                            </Table.Head>
                            <Table.Body overflowY="initial">
                                {currenciesArray?.map((currency) => {
                                    const { base: newBase, max: newMax } = newRates[currency];
                                    const { base: oldBase = "wasn't available", max: oldMax = "" } = oldRates[currency] || {};
                                    const { name: currencyName, slug: currencySlug, imageUrl } = symbolToCurrencyMap[currency];
                                    let baseClass = JSON.stringify(newBase) === JSON.stringify(oldBase) ? unchangedClass : 'row-changed';
                                    let maxClass = newMax === oldMax ? unchangedClass : 'row-changed max-row-changed';
                                    if (!areChangedRows && (maxClass === 'row-changed max-row-changed' || baseClass === 'row-changed')) {
                                        areChangedRows = true;
                                    }
                                    if (maxClass === 'hidden-row' && baseClass !== 'hidden-row') {
                                        maxClass = '';
                                        // baseClass = 'row-changed max-row-changed';
                                    }
                                    if (baseClass === 'hidden-row' && maxClass !== 'hidden-row') {
                                        baseClass = '';
                                    }
                                    const symbol = currencySlug ? <b><Link to={currencySlug}>{currency}</Link></b> : <b>{currency}</b>;
                                    return (
                                        <>
                                            <Table.Row isSelectable height="auto" borderBottom="none" className={baseClass}>
                                                <Table.TextCell paddingY={12} flex={0.9} title={`${currency} - ${currencyName}`} className="rates-table-left-column">
                                                    <img src={imageUrl} width="22"></img>&nbsp;&nbsp;{symbol}
                                                </Table.TextCell>
                                                <Table.TextCell paddingY={12} flex={2} className="wrap-text">{newBase}</Table.TextCell>
                                                <Table.TextCell paddingY={12} flex={2} className="wrap-text">{oldBase}</Table.TextCell>
                                                <Table.TextCell paddingY={12} flex={2}>(Base {aprOrApy}, paid in kind)</Table.TextCell>
                                            </Table.Row>
                                            <Table.Row isSelectable height="auto" className={maxClass}>
                                                <Table.TextCell paddingY={12} className="rates-table-left-column" flex={0.9}></Table.TextCell>
                                                <Table.TextCell paddingY={12} flex={2} className="wrap-text">{newMax}</Table.TextCell>
                                                <Table.TextCell paddingY={12} flex={2} className="wrap-text">{oldMax}</Table.TextCell>
                                                <Table.TextCell paddingY={12} flex={2}>(Max {aprOrApy})</Table.TextCell>
                                            </Table.Row>
                                        </>
                                )})}
                                {showChanged && !areChangedRows && <Table.Row><Table.TextCell flex={1.5} className="rates-table-left-column"><i>{getRatesType(pathname, dateEffective)}</i></Table.TextCell><Table.TextCell flex={1}></Table.TextCell></Table.Row>}
                            </Table.Body>
                        </Table>
                    </Pane>
                    {maxInfo && <Text>* {maxInfo}</Text>}
                    {rates.extras}
                </Pane>
            )}
        </div>
    );
}

export default RatesTable;