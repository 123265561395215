import React from 'react';
import { CELSIUS_BONUS_HTML } from '../utils/constants';
import { ratesUS as newUS, ratesInt as newInt, celsiusDateEffective } from './celsius-rates/6-10-22';
import { ratesUS as oldUS, ratesInt as oldInt } from './celsius-rates/5-20-22';

const celsiusPausedWithdrawals = <span>Celsius has paused all withdrawals, effective June 12, 22; <a target="_blank" rel="noopener" href="https://blog.celsius.network/a-memo-to-the-celsius-community-59532a06ecc6">Celsius Announcement</a></span>;

export const celsiusRowInfoUS = {
    name: 'Celsius',
    withdrawalFees: celsiusPausedWithdrawals,
    additionalInfo: 'non-accredited investors in the US can no longer earn interest on new deposits; pre-existing deposits can continue to earn interest',
    icon: '/images/celsius-logo.svg',
    order: 4,
    // bonus: '$50 in BTC',
    bonusLink: 'celsius-bonus',
    referralSlug: 'celsius-referral',
};

export const celsiusMaxRateInfo = ' - requires owning over 25% of portfolio in CEL (or 25,000 CEL), and receiving interest in CEL';
export const celsiusMaxRateInfoUS = ' - requires accredited investor status, owning over 25% of portfolio in CEL (or 25,000 CEL), and receiving interest in CEL';

export const celsiusRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Celsius',
    // bonusHTML: CELSIUS_BONUS_HTML,
    dateEffectiveUS: celsiusDateEffective,
    dateEffectiveInt: celsiusDateEffective,
    maxInfoInt: 'the max APY of each coin requires owning over 25% of portfolio in CEL (or 25,000 CEL), and receiving interest in CEL',
    maxInfoUS: 'the max APY of each coin requires US accredited investor status, owning over 25% of portfolio in CEL (or 25,000 CEL), and receiving interest in CEL',
    availabilityInfoUS: celsiusPausedWithdrawals,
    availabilityInfoInt: celsiusPausedWithdrawals,
    key: 'celsius',
};
