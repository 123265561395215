import React, { useState } from 'react';
import { Link } from '.';
import { symbolToCurrencyMap } from '../../utils/constants';
import useQueryParams from '../../utils/useQueryParams';
import useHideContent from '../../utils/useHideContent';
import { LocationControl, Select, ReferralModal } from '.';
import InfoPopover from './InfoPopover';
import { baseAPYPopover, maxAPYPopover } from './PlatformsTableHeader';
import { Button, Table, TabNavigation, Tab, ChevronDownIcon, Text, Pane, ArrowsHorizontalIcon } from 'evergreen-ui';
import LoadingSpinner from './LoadingSpinner';
import { createRatesSorter } from '../../data/ratesTableInfo';

const optionsArray = [{ val: 'baseAPY', text: 'Base APY' }, { val: 'maxAPY', text: 'Max APY' }]

const HighestRatesTable = ({ location, onLocationChange, rates, ratesSubdirectory, pathname }) => {
    const [hideContent] = useHideContent();
    const [rowsToShow, updateRowsToShow] = useState(20);
    const [selectValue, setSelectValue] = useState('maxAPY');
    const [isShown, setIsShown] = useState(false);
    const [modalPlaftorm, setModalPlatform] = useState({});
    const onClick = () => {
        updateRowsToShow(rowsToShow + 20);
    };

    const sortedRates = rates.sort(createRatesSorter(selectValue));
    const displayedRates = sortedRates.slice(0, rowsToShow);
    const tabsArray = [
        { label: 'All', path: ratesSubdirectory },
        { label: 'Stablecoins', path: ratesSubdirectory + 'stablecoins/' },
        { label: 'Cryptocurrencies', path: ratesSubdirectory + 'cryptocurrencies/' },
    ];

    const [search] = useQueryParams();

    return (
        <div className={hideContent}>
            <Pane display="flex" justifyContent="space-between" alignItems="end" flexWrap="wrap">
                <Pane>
                    <LocationControl location={location} onChange={onLocationChange} />
                    <TabNavigation paddingY={12} minWidth="300px">
                        {tabsArray.map(({ label, path }) => (
                            <Tab appearance="primary" key={label} is="a" href={path + search} id={label} isSelected={pathname === path}>
                                {label}
                            </Tab>
                        ))}
                    </TabNavigation>
                </Pane>
                <Select value={selectValue} setValue={setSelectValue} label="Sort By" options={optionsArray} paddingY={12} marginBottom={0} />
            </Pane>
            <Pane className="platforms-table-mobile">
                <ArrowsHorizontalIcon /> <Text size={300}>Table scrolls horizontally</Text>
            </Pane>
            <Pane paddingY={12} className="platforms-table-container">
                <Table minWidth={870} className="platforms-table">
                    <Table.Head height="auto" className="platforms-table-header">
                        <Table.TextHeaderCell paddingY={12} className="platforms-table-left-column" flex="initial" flexBasis="100px" borderTopLeftRadius={4}>
                            Currency
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell flex={2}>
                            Base APY (paid in kind) <InfoPopover content={baseAPYPopover} />
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12} flex={2}>Max APY <InfoPopover content={maxAPYPopover} /></Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12} flex="initial" flexBasis="122px">Platform</Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12} flexBasis={100} flexGrow={0}>Sign Up Bonus</Table.TextHeaderCell>
                        <Table.TextHeaderCell paddingY={12} flex={2}>Additional Info</Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body overflowY="initial">
                        {displayedRates?.map((platform) => {
                            const { baseAPY, maxAPY, name, icon, symbol, bonus, additionalInfo } = platform;
                            const symbolElement = symbolToCurrencyMap[symbol].slug ? <b><Link to={symbolToCurrencyMap[symbol].slug}>{symbol}</Link></b> : <b>{symbol}</b>;
                            return (
                                <>
                                    <Table.Row isSelectable height="auto">
                                        <Table.TextCell paddingY={12} flex="initial" flexBasis="100px" title={`${symbol} - ${symbolToCurrencyMap[symbol].name}`} className="platforms-table-left-column">
                                            <img src={symbolToCurrencyMap[symbol].imageUrl} alt={`${symbol} (${symbolToCurrencyMap[symbol].name}) Logo`} width="22"></img>&nbsp;&nbsp;{symbolElement}
                                        </Table.TextCell>
                                        <Table.TextCell paddingY={12} flex={2} className="wrap-text">{baseAPY}</Table.TextCell>
                                        <Table.TextCell paddingY={12} flex={2} className="wrap-text">{maxAPY}</Table.TextCell>
                                        <Table.TextCell paddingY={12} flex="initial" flexBasis="122px">
                                            <img src={icon} width="22" alt={`${name} Logo`}></img>&nbsp;&nbsp;{name}
                                        </Table.TextCell>
                                        <Table.TextCell paddingY={12} flexBasis={100} flexGrow={0} className="wrap-text">
                                            {platform.bonus || '-'}
                                            {platform.bonus && <div><a onClick={() => { setIsShown(true); setModalPlatform(platform); }}>see details</a></div>}
                                        </Table.TextCell>
                                        <Table.TextCell paddingY={12} flex={2} className="wrap-text">{additionalInfo}</Table.TextCell>
                                    </Table.Row>
                                </>
                        )})}
                    </Table.Body>
                </Table>
            </Pane>
            {(rates.length > rowsToShow) && <Pane textAlign="center" marginBottom="1.5em">
                    <Button color="#3366FF" size="large" appearance="minimal" iconAfter={ChevronDownIcon} onClick={onClick}>
                    Show More
                </Button>
            </Pane>}
            <ReferralModal platform={modalPlaftorm} isShown={isShown} setIsShown={setIsShown} />
        </div>
    );
}

export default HighestRatesTable;