import React from 'react';
export const lednDateEffective = '11/1/24';

const bitcoinAPY = <div>1% on first 2 BTC <br />2.25% for over 2 BTC</div>;
const ethereumAPY = <div>3% on first 50 ETH <br />3.5% for over 50 ETH</div>;
const usdCoinAPY = <div>7.5% on first 100k USDC <br />9.5% for over 100K USDC</div>;
const tetherAPY = <div>7.5% on first 100k USDT <br />9.5% for over 100K USDT</div>;

export const ratesUS = {};

export const ratesInt = {
    BTC: {
        base: bitcoinAPY,
        max: '2.25%',
    },
    USDC: {
        base: usdCoinAPY,
        max: '9.50%',
    },
    USDT: {
        base: tetherAPY,
        max: '9.50%',
    },
    ETH: {
        base: ethereumAPY,
        max: '3.50%',
    },
};
