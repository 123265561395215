import React from 'react';
import { Link } from '.';
import { Menu } from 'evergreen-ui';

const NavDropdownLink = ({ slug, imageUrl, label, ...props }) => {
    return (
        <Link to={slug} className="nav-dropdown-menu-items">
            <Menu.Item paddingLeft={0} {...props}><img src={imageUrl} />{label}</Menu.Item>
        </Link>
    );
}

export default NavDropdownLink;