import React, { useState } from 'react';
import { SegmentedControl } from 'evergreen-ui';

const LocationControl = ({ location, onChange }) => {
    const [options] = useState([
        { label: 'International', value: 'international' },
        { label: 'United States', value: 'us' },
    ]);
    return (
        <SegmentedControl width={240} options={options} value={location} onChange={onChange} paddingY={12} />
    );
}

export default LocationControl;
