import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import useQueryParams from '../../utils/useQueryParams';

const CustomLink = ({ to, children, ...props }) => {
    const [search] = useQueryParams();
    return (
        <Link to={to + search} {...props}>{children}</Link>
    );
};

export default CustomLink;
