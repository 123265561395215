import React from 'react';
import { Alert, Link, Text, Pill } from 'evergreen-ui';
// import { HARU_BONUS_HTML } from '../utils/constants';
import { ratesUS as newUS, ratesInt as newInt, haruDateEffective } from './haru-rates/6-1-23';
import { ratesUS as oldUS, ratesInt as oldInt } from './haru-rates/5-18-23';

export const haruAvailabilityInfoUS = 'Haru Invest is no longer available for new US users; pre-existing US users can continue to use the platform';
const baseAdditionalInfo = ', but no limit if you lock asset for 15 days or longer';
const haruHalted = <span>Haru Invest has halted any deposit and withdrawal requests, starting from June 13, 2023, at 0:40 UTC; <a target="_blank" rel="noopener" href="https://haruinvest.com/blog/important-update-june-13-2023/">Haru Announcement</a></span>;

export const haruWithdrawals = {
    BTC: '0.0001 BTC',
    USDT: '10 USDT',
    ETH: '0.004 ETH',
    XRP: '1 XRP',
    USDC: '10 USDC',
};

export const haruAddtionalInfoMap = {
    BTC: '5 BTC max limit' + baseAdditionalInfo,
    ETH: '100 ETH max limit' + baseAdditionalInfo,
    USDT: '100,000 USDT max limit' + baseAdditionalInfo,
    XRP: '200,000 XRP max limit' + baseAdditionalInfo,
    USDC: '100,000 USDC max limit' + baseAdditionalInfo,
};

export const haruInfoUS = {
    name: 'Haru Invest',
    icon: '/images/haru-logo.svg',
    order: 1,
    bonus: '$10 in USDT',
    bonusLink: 'haru-bonus',
    referralSlug: 'haru-invest-referral',
};

export const haruRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Haru Invest',
    // bonusHTML: HARU_BONUS_HTML,
    dateEffectiveUS: haruDateEffective,
    dateEffectiveInt: haruDateEffective,
    availabilityInfoUS: haruHalted,
    availabilityInfoInt: haruHalted,
    APR: true,
    maxInfoInt: 'the max APR of each coin requires locking asset for 365 days',
    maxInfoUS: 'the max APR of each coin requires locking asset for 365 days',
    key: 'haru',
    // extras: (
    //     <Alert marginTop="2rem" marginBottom="3rem" hasIcon={false} lineHeight={1}>
    //         <Text><Pill color="blue"  marginRight="1rem">Promo</Pill>Haru Invest is offering a one-time, 7-day lockup of 50% APR on a max amount of $300. <Link target="_blank" rel="noopener" href="https://haruinvest.com/blog/a-lockup-with-50-apr-one-time-offer">Learn More</Link></Text>
    //     </Alert>
    // )
};

export const haruMaxRateInfo = ' (APR) - highest rate requires locking asset for 365 days';

