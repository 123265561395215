const hardwareWalletsInfo = require('../data/hardwareWalletsInfo.json');

const slugify = (str) => {
    return str.toLowerCase().replace(/ /g, '-')
}
  
const getCombinations = (arr) => {
    const combinations = [];
  
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        combinations.push({
            slug: slugify(`${arr[i].walletName}-vs-${arr[j].walletName}`),
            title: `${arr[i].walletName} vs ${arr[j].walletName}`
        });
      }
    }
  
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        for (let k = j + 1; k < arr.length; k++) {
            combinations.push({
                slug: slugify(`${arr[i].walletName}-vs-${arr[j].walletName}-vs-${arr[k].walletName}`),
                title: `${arr[i].walletName} vs ${arr[j].walletName} vs ${arr[k].walletName}`,
            });
        }
      }
    }
  
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        for (let k = j + 1; k < arr.length; k++) {
          for (let l = k + 1; l < arr.length; l++) {
            combinations.push({
                slug: slugify(`${arr[i].walletName}-vs-${arr[j].walletName}-vs-${arr[k].walletName}-vs-${arr[l].walletName}`),
                title: `${arr[i].walletName} vs ${arr[j].walletName} vs ${arr[k].walletName} vs ${arr[l].walletName}`,
            });
          }
        }
      }
    }
    
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        for (let k = j + 1; k < arr.length; k++) {
          for (let l = k + 1; l < arr.length; l++) {
            for (let m = l + 1; m < arr.length; m++) {
            // combinations.push(slugify(`${arr[i].walletName}-vs-${arr[j].walletName}-vs-${arr[k].walletName}-vs-${arr[l].walletName}-vs-${arr[m].walletName}`));
                combinations.push({
                    slug: slugify(`${arr[i].walletName}-vs-${arr[j].walletName}-vs-${arr[k].walletName}-vs-${arr[l].walletName}-vs-${arr[m].walletName}`),
                    title: `${arr[i].walletName} vs ${arr[j].walletName} vs ${arr[k].walletName} vs ${arr[l].walletName} vs ${arr[m].walletName}`,
                });
            }
          }
        }
      }
    }
  
    return combinations;
  }
  
const walletNameCombinations = getCombinations(hardwareWalletsInfo);
module.exports = {
    walletNameCombinations,
};
  