import React from 'react';

const affiliateText = 'Some content may contain affiliate links, meaning that for referring you, we may receive a commission. This is at no cost to you, and may provide you with a bonus / discount as part of the referral offer.';

const AffiliateDisclosure = ({ large }) => {
    if (large) {
        return (
            <section className="post-full-content content-body load-external-scripts post-card-excerpt">
                <hr />
                <p><em>{affiliateText}</em></p>
            </section>
        );
    }

    return (
        <section className="post-full-content content-body load-external-scripts post-card-excerpt">
            <br />
            <p><em>{affiliateText}</em></p>
        </section>
    );
}

export default AffiliateDisclosure;
