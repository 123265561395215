export const cryptoComDateEffective = '11/20/24';

const tier1Limit = ' on first $3,000 of total assets in fixed terms';
const earnPlusLImit = ' on up to $500,000'

export const ratesUS = {
    CRO: {
        base: '0.25%',
        max: `6.00%${tier1Limit} - requires locking asset for 3 months`,
    },
    DAI: {
        base: '0.40%',
        max: '8.50% (6.50% in kind + 2% in CRO)' + tier1Limit,
    },
    USDC: { base: '0.50%', max: '15.00%' + earnPlusLImit },
    USDT: { base: '0.40%', max: '15.00%' + earnPlusLImit },
    BTC: {
        base: '0.20%',
        max: '7.00% (5.00% in kind + 2% in CRO)' + tier1Limit,
    },
    ETH: {
        base: '0.20%',
        max: '7.50% (5.50% in kind + 2% in CRO)' + tier1Limit,
    },
    ALGO: {
        base: '0.10%',
        max: '5.00% (3.00% in kind + 2% in CRO)' + tier1Limit,
    },
    ATOM: {
        base: '0.55%',
        max: '14.00% (12.00% in kind + 2% in CRO)' + tier1Limit,
    },
    FTM: {
        base: '0.10%',
        max: '5.00% (3.00% in kind + 2% in CRO)' + tier1Limit,
    },
    ADA: {
        base: '0.10%',
        max: '6.50% (4.50% in kind + 2% in CRO)' + tier1Limit,
    },
    BNB: {
        base: '0.10%',
        max: '3.50% (1.50% in kind + 2% in CRO)' + tier1Limit,
    },
    VET: {
        base: '0.10%',
        max: '4.00% (2.00% in kind + 2% in CRO)' + tier1Limit,
    },
    EGLD: {
        base: '0.35%',
        max: '8.00% (6.00% in kind + 2% in CRO)' + tier1Limit,
    },
    DOT: {
        base: '0.80%',
        max: '14.00% (12.00% in kind + 2% in CRO)' + tier1Limit,
    },
    MATIC: {
        base: '0.25%',
        max: '7.00% (5.00% in kind + 2% in CRO)' + tier1Limit,
    },
    ONE: {
        base: '0.25%',
        max: '6.50% (4.50% in kind + 2% in CRO)' + tier1Limit,
    },
    AVAX: {
        base: '0.30%',
        max: '10.50% (8.50% in kind + 2% in CRO)' + tier1Limit,
    },
    NEAR: {
        base: '0.25%',
        max: '6.50% (4.50% in kind + 2% in CRO)' + tier1Limit,
    },
    SOL: {
        base: '0.25%',
        max: '9.00% (7.00% in kind + 2% in CRO)' + tier1Limit,
    },
    STX: {
        base: '0.25%',
        max: '8.50% (6.50% in kind + 2% in CRO)' + tier1Limit,
    },
};

export const ratesInt = {
    ...ratesUS,
    APT: {
        base: '0.25%',
        max: '6.50% (4.50% in kind + 2% in CRO)' + tier1Limit,
    },
    ZIL: {
        base: '0.25%',
        max: '8.00% (6.00% in kind + 2% in CRO)' + tier1Limit,
    },
};
