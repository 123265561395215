import React from 'react';
import { Link } from '.';
import { Table } from 'evergreen-ui';
import { symbolToCurrencyMap } from '../../utils/constants';
import useHideContent from '../../utils/useHideContent';

const WithdrawalFeesTable = ({ withdrawalFees }) => {
    const [hideContent] = useHideContent();
    const feesCurrencies = Object.keys(withdrawalFees);
    feesCurrencies.sort();
    return (
        <div className={hideContent}>
            <Table maxWidth={400} marginX="auto" marginBottom="1.5em">
                <Table.Head position="sticky" top={-1}>
                    <Table.TextHeaderCell flex={1}>Currency</Table.TextHeaderCell>
                    <Table.TextHeaderCell flex={2}>Withdrawal Fee</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {feesCurrencies && feesCurrencies.map((currency) => {
                        const currencyObj = symbolToCurrencyMap[currency];
                        const symbol = currencyObj.slug ? <b><Link to={currencyObj.slug}>{currency}</Link></b> : <b>{currency}</b>;
                        return (
                            <Table.Row isSelectable height="auto" paddingY={12}>
                                <Table.TextCell flex={1} title={`${currency} - ${currencyObj.name}`}>
                                    <img src={currencyObj.imageUrl} width="22"></img>&nbsp;&nbsp;{symbol}
                                </Table.TextCell>
                                <Table.TextCell flex={2} className="wrap-text">{withdrawalFees[currency]}</Table.TextCell>
                            </Table.Row>
                    )})}
                </Table.Body>
            </Table>
        </div>
    );
};

export default WithdrawalFeesTable;
