import React, { useState, useEffect } from 'react';
import { ArrowUpIcon, CircleArrowUpIcon, UploadIcon, Pane } from 'evergreen-ui';
const GoToTop = () => {
  const [scrolled, setScrolled] = useState('opacity-zero');
 
  useEffect(() => {
    window.addEventListener('scroll',() => {
      const isTop = window.scrollY < 300;
      isTop !== true ? setScrolled('') : setScrolled('opacity-zero');
    });
  }, []);

  const handleGoToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // history.pushState("", document.title, window.location.pathname + window.location.search);
  } 

  return (
    <button className={`go-to-top-button ` + scrolled} onClick={() => handleGoToTop()} title="Go to top">
        <UploadIcon size={24} />
    </button>
  );
}

export default GoToTop;
