import { useState, useEffect } from 'react';

const useHideContent = () => {
    const [hideContent, updateHideContent] = useState('hide-content');

    useEffect(() => {
        updateHideContent('');
    }, []);

    return [hideContent];
};

export default useHideContent;
