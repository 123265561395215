import React from 'react';
import { Menu, Pane } from 'evergreen-ui';
import { NavDropdownLink, DropdownNav } from '.'
import { platformsSplit, platformMap } from '../../utils/constants';

const RatesByPlatformNav = () => {
    const popoverContent = (
        <Pane maxHeight="80vh" overflow="auto" display="flex">
            {platformsSplit.map((column) => (
                <Menu>
                    <Menu.Group>
                        {column.map((platform) => {
                            const { slug, name, imageUrl } = platformMap[platform];
                            return <NavDropdownLink slug={slug} imageUrl={imageUrl} label={name} />
                        })}
                    </Menu.Group>
                </Menu>
            ))}
        </Pane>
    );

    return <DropdownNav popoverContent={popoverContent}>Rates & Changes by Platform</DropdownNav>;
};

export default RatesByPlatformNav;
