import React from 'react';

const maxBitcoinAPY = '4.08% on first 0.1 BTC';
const hodlnautBitcoinAPY = <div>{maxBitcoinAPY} <br />3.25% for 0.1 to 1 BTC <br />1.61% for over 1 BTC</div>;

const maxWrappedBitcoinAPY = '4.08% on first 0.1 WBTC';
const hodlnautWrappedBitcoinAPY = <div>{maxWrappedBitcoinAPY} <br />3.25% for 0.1 to 1 WBTC <br />1.61% for over 1 WBTC</div>;

const maxEthAPY = '4.6% on first 5 ETH';
const hodlnautEthAPY = <div>{maxEthAPY} <br />4.08% for 5 to 100 ETH <br />2.02% for over 100 ETH</div>;

const maxUSDCoinAPY = '7.25% on first 100k USDC';
const hodlnautUSDCoinAPY = <div>{maxUSDCoinAPY} <br />5.23% for 100k to 500k USDC <br />0.5% for 500k to 1M USDC <br />0.1% for over 1M USDC</div>;

const maxTetherAPY = '7.25% on first 100k USDT';
const hodlnautTetherAPY = <div>{maxTetherAPY} <br />5.23% for 100k to 500k USDT <br />0.5% for 500k to 1M USDT <br />0.1% for over 1M USDT</div>;

const maxDaiAPY = '3.56% on first 25k DAI';
const hodlnautDaiAPY = <div>{maxDaiAPY} <br />2.53% for 25k to 100k DAI <br />0.25% for 100k to 500k DAI <br />0.05% for over 500k DAI</div>;

export const hodlnautDateEffective = '7/8/22';
const maxLock = ' (APR) - highest rate requires locking asset for 360 days';

export const ratesUS = {
    BTC: {
        base: hodlnautBitcoinAPY,
        max: '5.00%' + maxLock,
    },
    WBTC: {
        base: hodlnautWrappedBitcoinAPY,
        max: '5.00%' + maxLock,
    },
    ETH: {
        base: hodlnautEthAPY,
        max: '5.00%' + maxLock,
    },
    USDC: {
        base: hodlnautUSDCoinAPY,
        max: maxUSDCoinAPY,
    },
    USDT: {
        base: hodlnautTetherAPY,
        max: maxTetherAPY,
    },
    DAI: {
        base: hodlnautDaiAPY,
        max: '7.00%' + maxLock,
    },
    PAXG: {
        base: '1.00%',
        max: '1.20%' + maxLock,
    },
};

export const ratesInt = ratesUS;
