module.exports = {
    siteUrl: `https://www.cefirates.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `CeFi Rates | Compare the Best Crypto Interest Accounts`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Compare interest rates, withdrawal fees, sign up bonuses, and other factors, across the top CeFi platforms. Find the best place to earn on your crypto.`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `CeFi Rates`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
}