import React, { useState } from 'react';
import { Link } from '.';
import { slugToRatesMap } from '../../data/ratesTableInfo';
import { symbolToCurrencyMap, platformMap } from '../../utils/constants';
import useLocation from '../../utils/useLocation';
// import useHideContent from '../../utils/useHideContent';
import { LocationControl, PlatformsTable } from '.';
import InfoPopover from './InfoPopover';
import { baseAPYPopover, inKindPopover, maxAPYPopover } from './PlatformsTableHeader';
import { InlineAlert, Alert, Table, SegmentedControl, Checkbox, Text, Pane, ArrowsHorizontalIcon } from 'evergreen-ui';

const VersusTable = ({ ratesA, ratesB }) => {
    const [location, onChange] = useLocation();
    const combined = (
        <>
            {inKindPopover}
            <br/>
            {maxAPYPopover}
        </>
    );
    let platformARates;
    let platformBRates;
    let platformAmaxInfo;
    let platformBmaxInfo;
    let availabilityInfoPlatformA;
    let availabilityInfoPlatformB;
    if (location === 'international') {
        platformARates = ratesA.newInt;
        platformBRates = ratesB.newInt;
        platformAmaxInfo = ratesA.maxInfoInt;
        platformBmaxInfo = ratesB.maxInfoInt;
        availabilityInfoPlatformA = ratesA.availabilityInfoInt;
        availabilityInfoPlatformB = ratesB.availabilityInfoInt;
    } else {
        platformARates = ratesA.newUS;
        platformBRates = ratesB.newUS;
        platformAmaxInfo = ratesA.maxInfoUS;
        platformBmaxInfo = ratesB.maxInfoUS;
        availabilityInfoPlatformA = ratesA.availabilityInfoUS;
        availabilityInfoPlatformB = ratesB.availabilityInfoUS;
    }
    const platformACurrencies = Object.keys(platformARates);
    const platformBCurrencies = Object.keys(platformBRates);
    const currenciesArray = [...new Set([...platformACurrencies ,...platformBCurrencies])].sort();
    const bothAPR = ratesA.APR && ratesB.APR;
    const aRatesAPR = ratesA.APR && '(rates in APR)';
    const bRatesAPR = ratesB.APR && '(rates in APR)';
    return (
        <div>
            <LocationControl location={location} onChange={onChange} />
            {availabilityInfoPlatformA && <Alert marginTop="2rem" marginBottom="3rem" intent="warning" title={availabilityInfoPlatformA}></Alert>}
            {availabilityInfoPlatformB && <Alert marginTop="2rem" marginBottom="3rem" intent="warning" title={availabilityInfoPlatformB}></Alert>}
            {currenciesArray.length > 0  && <div>
                <Pane className="rates-table-mobile">
                    <ArrowsHorizontalIcon /> <Text size={300}>Table scrolls horizontally</Text>
                </Pane>
                <Pane paddingY={12} className="rates-table-container">
                    <Table minWidth={700} className="versus-table">
                        <Table.Head height="auto" className="versus-table-header">
                            <Table.TextHeaderCell className="versus-table-left-column" paddingY={12} flex={0.9} borderTopLeftRadius={4}>
                                Currency
                            </Table.TextHeaderCell>
                            {/* <Table.TextHeaderCell flex={2}>Base or Max {aprOrApy} <InfoPopover content={combined} /></Table.TextHeaderCell> */}
                            <Table.TextHeaderCell paddingY={12} flex={2}>
                                <img width={16} src={platformMap[ratesA.key].imageUrl} /> {ratesA.platform} {bothAPR || aRatesAPR}{platformAmaxInfo && ' *'}
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell paddingY={12} flex={2}>
                                <img width={16} src={platformMap[ratesB.key].imageUrl} /> {ratesB.platform} {bothAPR || bRatesAPR}{platformBmaxInfo && ' *'}
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell paddingY={12} flex={2}>
                                Base or Max {bothAPR ? 'APR' : 'APY'} <InfoPopover content={combined} />
                            </Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body overflowY="initial">
                            {currenciesArray?.map((currency) => {
                                const { base: baseA = 'unavailable', max: maxA = '' } = platformARates[currency] || {};
                                const { base: baseB = 'unavailable', max: maxB = '' } = platformBRates[currency] || {};
                                const ratesAClass = baseA === 'unavailable' ? 'disabled-cell' : '';
                                const ratesBClass = baseB === 'unavailable' ? 'disabled-cell' : '';
                                const { name: currencyName, slug: currencySlug, imageUrl } = symbolToCurrencyMap[currency];
                                const symbol = currencySlug ? <b><Link to={currencySlug}>{currency}</Link></b> : <b>{currency}</b>;
                                return (
                                    <>
                                        <Table.Row isSelectable height="auto" borderBottom="none">
                                            <Table.TextCell className="versus-table-left-column" paddingY={12} flex={0.9} title={`${currency} - ${currencyName}`}><img src={imageUrl} width="22"></img>&nbsp;&nbsp;{symbol}</Table.TextCell>
                                            <Table.TextCell disabled={true} paddingY={12} flex={2} className={`wrap-text ${ratesAClass}`}>{baseA}</Table.TextCell>
                                            <Table.TextCell paddingY={12} flex={2} className={`wrap-text ${ratesBClass}`}>{baseB}</Table.TextCell>
                                            <Table.TextCell paddingY={12} flex={2}>(Base {bothAPR ? 'APR' : 'APY'} paid in kind)</Table.TextCell>
                                        </Table.Row>
                                        <Table.Row isSelectable height="auto">
                                            <Table.TextCell className="versus-table-left-column" paddingY={12} flex={0.9}></Table.TextCell>
                                            <Table.TextCell paddingY={12} flex={2} className={`wrap-text ${ratesAClass}`}>{maxA}</Table.TextCell>
                                            <Table.TextCell paddingY={12} flex={2} className={`wrap-text ${ratesBClass}`}>{maxB}</Table.TextCell>
                                            <Table.TextCell paddingY={12} flex={2}>(Max {bothAPR ? 'APR' : 'APY'})</Table.TextCell>
                                        </Table.Row>
                                    </>
                            )})}
                        </Table.Body>
                    </Table>
                </Pane>
            </div>}
            {platformAmaxInfo && <div><Text><b>* {ratesA.platform}:</b> {platformAmaxInfo}</Text></div>}
            {platformBmaxInfo && <Text><b>* {ratesB.platform}:</b> {platformBmaxInfo}</Text>}
        </div>
    );
};

export default VersusTable;
