import React from 'react';

const CurrencyRatesFAQ = ({ symbol, currency, isStablecoin }) => {
    return (
        <section className="post-full-content content-body load-external-scripts smaller-headings">
            <h2>Best {symbol} Interest Rates</h2>
            <p>The table above compares the best {symbol} interest rates. As you’ll notice, there are base rates and max rates. Since the max rates often have certain requirements, such as locking the asset for a fixed amount of time, the best {currency} interest rate will depend on your willingness to fulfill any of those requirements.</p>
            <p>For example, platform A might have a higher base rate than platform B, but platform B can offer a higher max rate if you lock your {symbol} for one month. So the best {symbol} interest rate would depend on your willingness to lock the asset.</p>
            <h2>Where To Earn the Highest {symbol} Yield</h2>
            <p>The max APY column will show where you can earn the highest {symbol} yield, depending on your willingness to fulfill any requirements specified. Some platforms also have tiers, as specified, so you may only be able earn the highest {symbol} interest rate on the first X amount of {symbol}.</p>
            <p>In addition, there is a toggle above the table where you can change the location between United States and International. The platforms and {currency} rates may change for US vs non-US. The table also includes other details relevant to determining the highest {symbol} interest rates, such as pointing out when a platform is unavailable in a specific US state.</p>
            <h2>Best {symbol} APY / Best {symbol} APR</h2>
            <p>The table shows {symbol} rates in APY (Annual Percentage Yield) by default. However, some platforms advertise their rates in APR (Annual Percentage Rate), so the table will specify when rates are in APR. APY takes into account compound interest, while APR does not. If a platform has {symbol} rates in APR, you can use <a target="_blank" rel="noopener" href="https://www.aprtoapy.com/">tools like this</a> to convert {symbol} APR to {symbol} APY.</p>
            <h2>Best {symbol} Staking Rates / Best {symbol} Lending Rates</h2>
            <p>For earning yield, staking {symbol} is sometimes used interchangeably with lending {symbol}. So those looking for the best {symbol} staking rates to earn interest might be looking for the best {symbol} lending rates to earn interest. With the CeFi platforms covered here, you are lending your {symbol} to centralized entities, as a way to earn yield on {symbol}.</p>
            <h2>How To Earn Interest on {symbol}</h2>
            <p>To earn interest on {symbol}, an individual would create an account, then there is usually a KYC (Know Your Customer) process to verify your identity. From there, you can often just deposit {symbol} to start earning interest. To earn the highest {symbol} APY, as shown in the max APY column, there are sometimes other requirements, as specified in the table.</p>
            <h2>Why Earn {symbol} Interest</h2>
            <p>The high yield offered by {symbol} interest accounts is likely the main reason an individual would want to earn {symbol} interest, rather than letting it sit idle. 
            {isStablecoin && ` Also, since ${currency} is a stablecoin pegged to the US dollar, one might want to earn yield on ${symbol} due to the low volatility relative to the US dollar.`}</p>
            <p>However, there is always risk that you lose some or all of your assets. For example, {isStablecoin && `${currency} could depeg from the US dollar, or`} a CeFi platform could halt withdrawals on the coins you deposited. Do your own research to make sure you understand all the risks of any particular cryptocurrency, or platform holding your crypto. No cryptocurrency or platform is without risk.</p>
            <h2>Best {symbol} Interest Account / Best {symbol} Savings Account</h2>
            <p>CeFi platforms are sometimes referred to as crypto interest accounts or crypto savings accounts (though very different from traditional bank accounts). The best {symbol} interest account / best {symbol} savings account might vary based on an individual’s preferences and situation.</p>
            <p>For instance, some platforms have tiers, so what might be ideal for one person, might be less ideal for someone with a much higher amount of {symbol}. You also wouldn’t want to use a platform that you believe is too risky, even if everything else seems ideal.</p>
            <hr></hr>
        </section>
    );
}

export default CurrencyRatesFAQ;
