export const abraDateEffective = '11/2/22';

export const ratesUS = {
    AVAX: {
        base: '7.00%',
        max: '7.00%',
    },
    ADA: {
        base: '3.00%',
        max: '3.00%',
    },
    LTC: {
        base: '2.00%',
        max: '2.00%',
    },
    BCH: {
        base: '5.00%',
        max: '5.00%',
    },
    XLM: {
        base: '2.50%',
        max: '2.50%',
    },
    CPRX: {
        base: '3.00%',
        max: '3.00%',
    },
    BTC: {
        base: '2.25%',
        max: '2.25%',
    },
    ETH: {
        base: '3.50%',
        max: '3.50%',
    },
    TUSD: {
        base: '7.00%',
        max: '7.00%',
    },
    USDT: {
        base: '10.00%',
        max: '10.00%',
    },
    USDC: {
        base: '7.00%',
        max: '7.00%',
    },
    USDP: {
        base: 'no longer available',
        max: '',
    },
    DOT: {
        base: '10.00%',
        max: '10.00%',
    },
    MATIC: {
        base: '6.00%',
        max: '6.00%',
    },
};

export const ratesInt = ratesUS;
