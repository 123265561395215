import { ABRA_BONUS_HTML } from '../utils/constants';
import { ratesUS as newUS, ratesInt as newInt, abraDateEffective } from './abra-rates/11-21-22';
import { ratesUS as oldUS, ratesInt as oldInt } from './abra-rates/11-2-22';

// const abraAdditionalInfoInt = 'must transfer from Interest Account to Trading (can take 1 to 7 days) before withdrawing';
const availabilityInfoUS = 'Abra no longer publically publishes rates';
export const abraRowInfoUS = {
    name: 'Abra',
    withdrawalFees: "network fees + withdrawal fee from Bittrex (Abra's custodian)",
    additionalInfo: availabilityInfoUS,
    icon: '/images/abra-logo.png',
    order: 10,
    bonus: 'none currently',
    bonusLink: 'abra-bonus',
    referralSlug: 'abra-referral',
};

export const abraRowInfoInt = {
    additionalInfo: '-',
};

export const abraMaxRateInfo = ' - requires holding 50,000 CPRX';

export const abraRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Abra',
    bonusHTML: ABRA_BONUS_HTML,
    dateEffectiveUS: abraDateEffective,
    dateEffectiveInt: abraDateEffective,
    availabilityInfoUS: availabilityInfoUS,
    availabilityInfoInt: availabilityInfoUS,
    // maxInfoInt: 'the max APY of each coin (except CPRX) requires holding 50,000 CPRX',
    // maxInfoUS: 'the max APY of each coin (except CPRX) requires holding 50,000 CPRX',
    key: 'abra',
};
