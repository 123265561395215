import React from 'react';
import { VOYAGER_BONUS_HTML } from '../utils/constants';
import { ratesUS as newUS, ratesInt as newInt, voyagerDateEffective } from './voyager-rates/6-1-22';
import { ratesUS as oldUS, ratesInt as oldInt } from './voyager-rates/5-1-22';

const availabilityInfoInt = 'Voyager is only available in the United States';
export const voyagerCantWithdraw = 'cannot be deposited or withdrawn, only bought or sold through Voyager';
const voyagerPaused = <span>Voyager has suspended trading, deposits, withdrawals, and loyalty rewards, effective July 1, 2022; <a target="_blank" rel="noopener" href="https://www.investvoyager.com/blog/voyager-update-july-1-2022/">Voyager Announcement</a></span>;

export const voyagerRowInfoUS = {
    name: 'Voyager',
    withdrawalFees: 'withdrawal fees are dynamic, fee is shown at the time of withdrawal',
    icon: '/images/voyager-logo.png',
    order: 6,
    // bonus: '$50 in BTC',
    bonusLink: 'voyager-bonus',
    referralSlug: 'voyager-referral',
};

const baseAdditionalInfo = 'may require up to 7 days to withdraw; unavailable in KY, OK, and NY';
export const voyagerAddtionalInfoMap = {
    BTC: '0.01 BTC min monthly balance and 100 BTC max balance; ' + baseAdditionalInfo,
    ETH: '0.5 ETH min monthly balance and 500 ETH max balance; ' + baseAdditionalInfo,
    LTC: '5 LTC minimum monthly balance; ' + baseAdditionalInfo,
    LINK: '40 LINK minimum monthly balance; ' + baseAdditionalInfo,
    USDC: '100 USDC minimum monthly balance; ' + baseAdditionalInfo,
    BCH: '0.5 BCH minimum monthly balance; ' + baseAdditionalInfo,
    XLM: '1,000 XLM minimum monthly balance; ' + baseAdditionalInfo,
    DOT: '20 DOT minimum monthly balance; ' + baseAdditionalInfo,
    MATIC: '400 MATIC minimum monthly balance; ' + baseAdditionalInfo,
    MANA: '1,000 MANA minimum monthly balance; ' + baseAdditionalInfo,
    AAVE: '1 AAVE minimum monthly balance; ' + baseAdditionalInfo,
    BAT: '300 BAT minimum monthly balance; ' + baseAdditionalInfo,
    UNI: '50 UNI minimum monthly balance; ' + baseAdditionalInfo,
    DOGE: '4,000 DOGE minimum monthly balance; ' + baseAdditionalInfo,
    ADA: '300 ADA minimum monthly balance; ' + baseAdditionalInfo,
    EOS: '250 EOS minimum monthly balance; ' + baseAdditionalInfo,
    XTZ: '50 XTZ minimum monthly balance; ' + baseAdditionalInfo,
    GRT: '1,500 GRT minimum monthly balance; ' + baseAdditionalInfo,
    COMP: '4 COMP minimum monthly balance; ' + baseAdditionalInfo,
    ZRX: '1,000 ZRX minimum monthly balance; ' + baseAdditionalInfo,
    LUNA: '10 LUNA minimum monthly balance; ' + baseAdditionalInfo,
    SOL: '6 SOL minimum monthly balance; ' + baseAdditionalInfo,
    ZEC: '2 ZEC minimum monthly balance; ' + baseAdditionalInfo,
    UMA: '25 UMA minimum monthly balance; ' + baseAdditionalInfo,
    KNC: '100 KNC minimum monthly balance; ' + baseAdditionalInfo,
    OMG: '50 OMG minimum monthly balance; ' + baseAdditionalInfo,
    SHIB: '20,000,000 SHIB minimum monthly balance; ' + baseAdditionalInfo,
    ETC: '10 ETC minimum monthly balance; ' + baseAdditionalInfo,
    ATOM: '20 ATOM minimum monthly balance; ' + baseAdditionalInfo,
    OXT: '500 OXT minimum monthly balance; ' + baseAdditionalInfo,
    FIL: '25 FIL minimum monthly balance; ' + baseAdditionalInfo,
    ALGO: '200 ALGO minimum monthly balance; ' + baseAdditionalInfo,
    APE: '10 APE minimum monthly balance; ' + baseAdditionalInfo,
    CELO: '50 CELO minimum monthly balance; ' + baseAdditionalInfo,
    DASH: '1.5 DASH minimum monthly balance; ' + baseAdditionalInfo,
    KAVA: '100 KAVA minimum monthly balance; ' + baseAdditionalInfo,
    KSM: '3 KSM minimum monthly balance; ' + baseAdditionalInfo,
    STMX: '30,000 STMX minimum monthly balance; ' + baseAdditionalInfo,
    VGX: '100 VGX minimum monthly balance; ' + baseAdditionalInfo,
    AVAX: '3 AVAX minimum monthly balance; ' + baseAdditionalInfo,
};

export const makeVoyagerMaxRateInfo = (rawMaxRate) => {
    const split = rawMaxRate.split(')');
    const maxRate = split.join(') APR,');
    // return maxRate;
    return (
        <div>
            {maxRate.split(' -')[0]}
            <br /><br />
            highest rate requires holding 20,000 VGX in your portfolio  
        </div>
    );
};

export const voyagerRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Voyager',
    // bonusHTML: VOYAGER_BONUS_HTML,
    dateEffectiveUS: voyagerDateEffective,
    dateEffectiveInt: voyagerDateEffective,
    availabilityInfoUS: voyagerPaused,
    availabilityInfoInt: voyagerPaused,
    APR: true,
    key: 'voyager',
};
