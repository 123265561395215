import React from 'react';

const celsiusBitcoinAPY = <div>6.5% on first 0.1 BTC <br />2.5% for 0.1 to 3 BTC <br />1% for over 3 BTC</div>;
const celsiusEthAPY = <div>6% on first 2 ETH <br />4% for 2 to 25 ETH <br />3.52% for over 25 ETH</div>;

export const celsiusDateEffective = '6/10/22';

export const ratesUS = {
    TUSD: { base: '7.10%', max: '9.32%' },
    GUSD: { base: '7.10%', max: '9.32%' },
    PAX: { base: '7.10%', max: '9.32%' },
    USDC: { base: '7.10%', max: '9.32%' },
    USDT: { base: '7.10%', max: '9.32%' },
    TGBP: { base: '2.53%', max: '3.30%' },
    TAUD: { base: '2.53%', max: '3.30%' },
    THKD: { base: '2.53%', max: '3.30%' },
    TCAD: { base: '2.53%', max: '3.30%' },
    BUSD: { base: '7.10%', max: '9.32%' },
    DAI: { base: '4.60%', max: '6.02%' },
    ZUSD: { base: '4.60%', max: '6.02%' },
    PAXG: { base: '5.50%', max: '7.21%' },
    SNX: { base: '14.05%', max: '18.63%' },
    MATIC: { base: '7.25%', max: '9.52%' },
    DOT: { base: '9.02%', max: '11.87%' },
    AVAX: { base: '3.82%', max: '4.99%' },
    BNT: { base: '5.50%', max: '7.21%' },
    SOL: { base: '3.82%', max: '4.99%' },
    SGB: { base: '5.50%', max: '7.21%' },
    CEL: { base: '5.10%', max: '6.68%' },
    '1INCH': { base: '5.02%', max: '6.57%' },
    AAVE: { base: '3.82%', max: '4.99%' },
    DASH: { base: '1.75%', max: '2.29%' },
    COMP: { base: '1.75%', max: '2.29%' },
    BCH: { base: '1.25%', max: '1.62%' },
    EOS: { base: '3.82%', max: '4.99%' },
    CRV: { base: '3.56%', max: '4.65%' },
    XTZ: { base: '4.25%', max: '5.55%' },
    SUSHI: { base: '4.13%', max: '5.40%' },
    ADA: { base: '4.06%', max: '5.31%' },
    ETH: {
        base: celsiusEthAPY,
        max: '7.87% on first 2 ETH',
    },
    LTC: { base: '1.75%', max: '2.29%' },
    BTC: {
        base: celsiusBitcoinAPY,
        max: '8.53% on first 0.1 BTC',
    },
    WBTC: { base: '3.05%', max: '3.99%' },
    ETC: { base: '2.25%', max: '2.94%' },
    LINK: { base: '1.75%', max: '2.29%' },
    UNI: { base: '2.50%', max: '3.26%' },
    BSV: { base: '2.02%', max: '2.63%' },
    ZEC: { base: '0.50%', max: '0.65%' },
    ZRX: { base: '0.50%', max: '0.65%' },
    XLM: { base: '1.00%', max: '1.31%' },
    BAT: { base: '1.00%', max: '1.31%' },
    UMA: { base: '1.00%', max: '1.31%' },
    OMG: { base: '0.50%', max: '0.65%' },
    KNC: { base: '0.50%', max: '0.65%' },
    MKR: { base: '0.50%', max: '0.65%' },
    CVX: { base: '0.50%', max: '0.65%' },
    MANA: { base: '0.25%', max: '0.33%' },
    YFI: { base: '0.01%', max: '0.01%' },
    BADGER: { base: '0.01%', max: '0.01%' },
};

export const ratesInt = {
    ...ratesUS,
    BNB: { base: '4.75%', max: '6.21%' },
    WDGLD: { base: '5.50%', max: '7.21%' },
    XAUT: { base: '5.50%', max: '7.21%' },
    XRP: { base: '2.50%', max: '3.26%' },
    DOGE: { base: '0.50%', max: '0.65%' },
};
