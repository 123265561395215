import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby';
import { cryptoCurrencies, symbolToCurrencyMap } from '../../utils/constants';
import useQueryParams from '../../utils/useQueryParams';
import { pathnameToCurrencyUSMap, pathnameToCurrencyIntMap } from '../../data/tableInfo';
import useLocation from '../../utils/useLocation';
import useHideContent from '../../utils/useHideContent';
import PlatformsTableHeader from './PlatformsTableHeader';
import { LocationControl, ReferralModal } from '.';
import { TabNavigation, Tab, Table, Combobox, ChevronDownIcon, SearchInput, Text, Pane, ArrowsHorizontalIcon, Button } from 'evergreen-ui';

const PlatformsTable = ({ pathname, showTabs, symbol }) => {
    const [hideContent] = useHideContent();
    const [search] = useQueryParams();
    const [location, onChange] = useLocation();
    const [isShown, setIsShown] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);
    const [modalPlaftorm, setModalPlatform] = useState({});
    const [filter, updateFilter] = useState('');
    const filteredCurrencies = cryptoCurrencies.filter(symbol => {
        const fullCurrencyName = `${symbol} ${symbolToCurrencyMap[symbol].name}`.toLowerCase();
        return fullCurrencyName.includes(filter.toLowerCase().trim());
    })

    let tableData;
    if (location === 'international') {
        tableData = pathnameToCurrencyIntMap[pathname];
    } else {
        tableData = pathnameToCurrencyUSMap[pathname];
    }

    if (!tableData) {
        return null;
    }

    tableData.sort((a, b) => a.order - b.order);
    const showMoreTabs = isShowMore || filter;

    return (
        <div className={hideContent}>
            <LocationControl location={location} onChange={onChange} />
            {!showTabs && <Pane className="platforms-table-mobile">
                <Combobox
                    marginY={12}
                    openOnFocus
                    items={cryptoCurrencies}
                    itemToString={symbol => (symbol ? `${symbol} - ${symbolToCurrencyMap[symbol].name}` : '')}
                    onChange={symbol => symbol && navigate(symbolToCurrencyMap[symbol].slug + search)}
                    // initialSelectedItem={initialSelectedItem}
                    placeholder="Change currency..."
                    // autocompleteProps={{ renderItem: () => <b>helloo</b> }}
                    // size="large"
                    autocompleteProps={{ itemSize: 44 }}
                />
            </Pane>}
            <Pane className={showTabs ? '' : 'show-desktop'}>
                <SearchInput autoComplete="off" placeholder="Filter currencies..." value={filter} onChange={e => updateFilter(e.target.value)}  />
                <Pane className="currencies-tab-container" maxHeight={showMoreTabs && 'initial'}>
                    {!showMoreTabs && <div className="currencies-tabs-fade"></div>}
                    <TabNavigation paddingY={12}>
                        {filteredCurrencies.length === 0 && <Text>No results</Text>}
                        {filteredCurrencies.map((symbol) => (
                            <Tab key={symbol} is="a" href={symbolToCurrencyMap[symbol].slug + search} id={symbol} isSelected={pathname === symbolToCurrencyMap[symbol].slug} title={`${symbol} - ${symbolToCurrencyMap[symbol].name}`}>
                                {symbol}
                            </Tab>
                        ))}
                    </TabNavigation>
                </Pane>
                {!showMoreTabs && <Pane textAlign="center" className="show-more-currency-button">
                    <Button color="#3366FF" size="large" appearance="minimal" iconAfter={ChevronDownIcon} onClick={() => setIsShowMore(true)}>
                        Show More
                    </Button>
                </Pane>}
            </Pane>
            <Pane className="platforms-table-mobile">
                <ArrowsHorizontalIcon /> <Text size={300}>Table scrolls horizontally</Text>
            </Pane>
            <Pane paddingY={12} className="platforms-table-container">
                <Table minWidth={870} className="platforms-table">
                    <PlatformsTableHeader />
                    <Table.Body overflowY="initial">
                        {tableData.length === 0 && <>
                            <Table.Row isSelectable height="auto">
                                <Table.TextCell paddingY={24} className="wrap-text platforms-table-left-column" maxWidth="90vw" borderRightWidth={0}>
                                    <i>No platforms available for {symbol} in the US. <a onClick={() => onChange('international')}>See international {symbol} rates.</a></i>
                                </Table.TextCell>
                            </Table.Row>
                        </>}
                        {tableData?.map((platform) => {
                            if (platform.name === 'Vauld' || platform.name === 'Celsius' || platform.name === 'Hodlnaut' || platform.name === 'BlockFi' || platform.name === 'Gemini Earn' || platform.name === 'Haru Invest'  || platform.name === 'Abra') {
                                return null;
                            }
                            return (
                                <Table.Row key={platform.name} isSelectable height="auto">
                                    <Table.TextCell paddingY={12} className="platforms-table-left-column" flexBasis={125} flexGrow={0}>
                                        <img width="24" height="24" src={platform.icon} alt={platform.name + ' logo'} />&nbsp;&nbsp;<span>{platform.name}</span>
                                    </Table.TextCell>
                                    {/* <Table.TextCell>{platform.name}</Table.TextCell> */}
                                    <Table.TextCell paddingY={12} className="wrap-text">{platform.baseAPY}</Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text">{platform.maxAPY}</Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text">{platform.withdrawalFees}</Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text" flexBasis={90} flexGrow={0}>
                                        {platform.bonus || '-'}
                                        {platform.bonus && <div><a onClick={() => { setIsShown(true); setModalPlatform(platform); }}>see details</a></div>}
                                    </Table.TextCell>
                                    <Table.TextCell paddingY={12} className="wrap-text">{platform.additionalInfo}</Table.TextCell>
                                </Table.Row>
                        )})}
                    </Table.Body>
                </Table>
            </Pane>
            <ReferralModal platform={modalPlaftorm} isShown={isShown} setIsShown={setIsShown} />
        </div>
    );
}

export default PlatformsTable;