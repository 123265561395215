import React from 'react';

const PlatformRatesFAQ = ({ aprOrApy, platform, cryptoSubset }) => {
    let modifier = '';
    let modifierAlt = modifier;
    let modifierAlt2 = ' coins / tokens';
    // let modifierAlt3 = 'crypto assets';
    let modifierPlural = '';

    let titleInterestRates = `What Are the ${platform} Interest Rates / Earn Rates?`;
    let titleAPY = `What APY / APR Does ${platform} Offer?`;
    let titleList = `What Coins / Tokens Are Available on ${platform} to Earn Yield?`;
    let titleRatesChanges = `What Are the Recent ${platform} Rate Changes?`;
    // let titleYield = `How To Earn Yield / Rewards on ${platform}?`;
    if (cryptoSubset === 'stablecoins') {
        modifier = ' stablecoin';
        modifierAlt = modifier;
        modifierPlural = ' stablecoins';
        modifierAlt2 = modifierPlural;

        titleInterestRates = `What Are the ${platform} Stablecoin Interest Rates?`;
        titleAPY = `What APY / APR Does ${platform} Offer on Stablecoins?`;
        titleList = `What Stablecoins Are Available on ${platform} to Earn Yield?`;
        titleRatesChanges = `What Are the Recent ${platform} Stablecoin Rate Changes?`;
    } else if (cryptoSubset === 'cryptocurrencies') {
        modifier = ' cryptocurrency';
        modifierAlt = modifier + ' (non-stablecoin)';
        modifierPlural = ' cryptocurrencies';
        modifierAlt2 = modifierPlural;

        titleInterestRates = `What Are the ${platform} Cryptocurrency Interest Rates?`;
        titleAPY = `What APY / APR Does ${platform} Offer on Cryptocurrencies?`;
        titleList = `What Cryptocurrencies Are Available on ${platform} to Earn Yield?`;
        titleRatesChanges = `What Are the Recent ${platform} Cryptocurrency Rate Changes?`;
    }

    let paragraphInteresRates =  `The ${platform}${modifier} rates shown in the table above are in APY (Annual Percentage Yield), unless otherwise specified. APY takes into account compound interest, whereas APR (Annual Percentage Rate) does not.`
    if (aprOrApy === 'APR') {
        paragraphInteresRates = `The ${platform}${modifier} rates shown in the table above are in APR (Annual Percentage Rate), unless otherwise specified. APY (Annual Percentage Yield) takes into account compound interest, whereas APR does not.`
    }
    return (
        <section className="post-full-content content-body load-external-scripts smaller-headings">
            <h2>{titleInterestRates}</h2>
            <p>The table above shows the current {platform}{modifierAlt} interest rates. It also displays the previous rates, so you can see the recent{modifier} rate changes. Some rates may vary based on location. At the top of the table is a toggle to change location between the United States and International.</p>
            <h2>{titleAPY}</h2>
            <p>{paragraphInteresRates}</p>
            <h2>{titleList}</h2>
            <p>The table above lists the{modifierAlt2} available for earning interest on {platform}, along with the current and previous rates. The{modifierAlt2} supported by {platform} may vary based on the location chosen in the toggle.</p>
            <h2>{titleRatesChanges}</h2>
            <p>The table compares the new {platform}{modifier} rates against the previous rates, so that you can see which ones recently changed. Rates that changed are highlighted. You can also select the checkbox above the table to only show assets with a rate change.</p>
            {/* <h2>How To Earn Yield / Rewards on {platform}?</h2>
            <p>After creating a {platform} account and depositing crypto, yield is then paid out at the beginning of each month. The interest earned compounds, contributing to the APY offered for each asset.</p> */}
        </section>
    );
}

export default PlatformRatesFAQ;
