import React from 'react';
import { ExpandableList, Link } from '.';
import { Pane, SideSheet, MenuIcon, Position, CrossIcon, Strong } from 'evergreen-ui';
import { currencies3Split, symbolToCurrencyMap, platforms, platformMap, highestRates } from '../../utils/constants';

const highestRatesLabel = <span>Highest Rates</span>;
const listItems = [
    { label: 'Rates by Currency', children: currencies3Split, splitColumns: true, map: symbolToCurrencyMap },
    { label: 'Rates & Changes by Platform', children: platforms, map: platformMap },
    { label: highestRatesLabel, children: highestRates },
];

const MobileNavSheet = ({ location }) => {
    const [isShown, setIsShown] = React.useState(false);
    const checkPath = (slug) => {
        if (location.pathname === slug) {
            setIsShown(false);
        }
    }
    return (
        <div className="mobile-nav">
            <SideSheet preventBodyScrolling width="100vw" position={Position.RIGHT} isShown={isShown} onCloseComplete={() => setIsShown(false)}>
                <Pane textAlign="right" marginX={15} marginTop={20} paddingX={10} paddingY={5}>
                    <CrossIcon size={24} onClick={() => setIsShown(false)} />
                </Pane>
                <Pane className="mobile-nav-sheet" marginX={25} marginBottom={40}>
                    {listItems.map((item) => <ExpandableList checkPath={checkPath} item={item} />)}
                    <Pane marginY={20}>
                        <Strong fontSize={18} color="black"><Link to="/best-hardware-wallets/">Best Hardware / Cold Wallets</Link></Strong>
                    </Pane>
                    <Pane>
                        {/* <Link className="site-nav-item" to="/referrals/">Referrals / Sign Up Bonuses</Link> */}
                        <Strong fontSize={18} color="black"><a href="mailto:hello@cefirates.com">Contact Us</a></Strong>
                    </Pane>
                </Pane>
            </SideSheet>
            <button className="site-nav-item" onClick={() => setIsShown(true)}>
                <MenuIcon  />
            </button>
        </div>
    )
}

export default MobileNavSheet;
