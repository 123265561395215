import React from 'react'
import { Text, Card, Strong, KeyIcon, Paragraph } from 'evergreen-ui';
import { Link } from '.'

const NonCustodialCard = () => {
    return ( 
        <Card border padding={16} background="orange25">
            <KeyIcon color="orange500" marginRight={16} />
            <Strong >Not your keys, not your coins.</Strong>
            {/* <Paragraph>Lending your crypto can carry <Link to="/platforms-halting-withdrawals/">significant risk</Link>. Consider using a non-custodial wallet such as <a target="_blank" rel="noopener" href="https://shop.ledger.com/?referral_code=1FYVCPXPH9C54">Ledger</a>.</Paragraph> */}
            <Paragraph>Lending your crypto can carry <Link to="/platforms-halting-withdrawals/">significant risk</Link>. Consider using a <Link to="/what-is-a-crypto-hardware-cold-wallet/">non-custodial, hardware wallet</Link> such as <Link to="/best-hardware-wallets/">Ledger, Trezor, or Jade</Link>.</Paragraph>
        </Card> 
    );
}

export default NonCustodialCard;