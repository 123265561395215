import React from 'react';
import { Table, Tooltip, Position, InfoSignIcon, Popover, Pane, Paragraph } from 'evergreen-ui';
import InfoPopover from './InfoPopover';
{/* <Link to="/">Learn more about APYs</Link> */}
export const inKindPopover = (
    <Paragraph>
        <b>Paid in kind</b> means you will be paid interest in the currency you deposit. So if you deposit BTC you will receive interest in BTC.
    </Paragraph>
);

export const baseAPYPopover = (
    <>
        <Paragraph>
            The <b>APY or Annual Percentage Yield</b>, is the total interest paid, based on the interest rate and frequency of compounding. It tells you exactly how much money a deposit will earn if deposited for 12 months.
        </Paragraph>
        <br/>
        {inKindPopover}
    </>
);

// https://www.fdic.gov/regulations/laws/rules/6500-3270.html
// https://www.investopedia.com/terms/a/apy.asp

export const maxAPYPopover = (
    <Paragraph>
        The <b>Max APY / APR</b> is the highest rate you can earn on a platform, for a particular asset. Some platforms will give a higher rate based on certain criteria such as holding their token or depositing your assets for a fixed amount of time.
    </Paragraph>
);

const PlatformsTableHeader = () => {
    return (
        <Table.Head className="platforms-table-header" height="auto">
            {/* <Table.SearchHeaderCell /> */}
            <Table.TextHeaderCell paddingY={12} borderTopLeftRadius={4} className="platforms-table-left-column" flexBasis={125} flexGrow={0}>
                Platform
            </Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>Platform</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>
                Base APY (paid in kind) <InfoPopover content={baseAPYPopover} />
            </Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>Max APY <Tooltip content="hello" position={Position.TOP}><InfoSignIcon /></Tooltip></Table.TextHeaderCell> */}
            <Table.TextHeaderCell paddingY={12}>Max APY <InfoPopover content={maxAPYPopover} /></Table.TextHeaderCell>
            <Table.TextHeaderCell paddingY={12}>Withdrawal Fee</Table.TextHeaderCell>
            <Table.TextHeaderCell paddingY={12} flexBasis={90} flexGrow={0}>Sign Up Bonus</Table.TextHeaderCell>
            <Table.TextHeaderCell paddingY={12}>Additional Info</Table.TextHeaderCell>
        </Table.Head>
    );
}

export default PlatformsTableHeader;
