import { useState, useEffect } from 'react';
import { useQueryParam, StringParam } from "use-query-params";

const useLocation = () => {
    const [location, setLocation] = useState('international');
    const [paramLocation, setParamLocation] = useQueryParam("location", StringParam);

    const onChange = (value) => {
        if (value === 'us') {
            setParamLocation('us');
        } else {
            setParamLocation(undefined);
        }
    }

    useEffect(() => {
        if (paramLocation?.toLowerCase() === 'us') {
            setLocation('us');
        } else {
            setLocation('international');
        }
    }, [paramLocation]);

    return [location, onChange];
};

export default useLocation;
