import React from 'react';

import {
    slugBTC,
    slugETH,
    slugUSDT,
    slugUSDC,
    slugDAI,
    slugGUSD,
    slugLTC,
    slugBCH,
    slugLINK,
    slugDOT,
    slugXLM,
    slugMATIC,
    slugMANA,
    slugAAVE,
    slugBAT,
    slugUNI,
    slugDOGE,
    slugADA,
    slugEOS,
    slugXTZ,
    slugGRT,
    slugCOMP,
    slugMKR,
    slugPAXG,
    slugXRP,
    slugZRX,
    slugBUSD,
    slugLUNA,
    slugSOL,
    slugSUSHI,
    slugZEC,
    slugSNX,
    slug1INCH,
    slugTUSD,
    slugUMA,
    slugKNC,
    slugAVAX,
    slugOMG,
    slugSHIB,
    slugBNB,
    slugETC,
    slugBNT,
    slugATOM,
    slugOXT,
    slugFIL,
    slugFTM,
    slugAXS,
    slugUSDP,
    slugUST,
    slugAPE,
    slugNEAR,
    slugCRV,
    slugYFI,
    slugALGO,
    slugWBTC,
    slugAMP,
    slugFLOW,
    slugCELR,
    slugANKR,
    slugBAL,
    slugTRX,
    slugEGLD,
    slugENJ,
    slugLPT,
    slugSTORJ,
    slugINJ,
    slugUSDD,
    slugSLP,
    slugONE,
    slugZIL,
    slugVET,
    slugRON,
    slugCRO,
} from '../utils/constants';
import { blockfiWithdrawals, blockfiRowInfoUS, blockfiRates } from './blockfi-info';
import { celsiusRowInfoUS, celsiusMaxRateInfo, celsiusMaxRateInfoUS, celsiusRates } from './celsius-info';
import { nexoRowInfoInt, nexoCantWithdraw, nexoRates } from './nexo-info';
import { voyagerRowInfoUS, voyagerAddtionalInfoMap, voyagerCantWithdraw, voyagerRates, makeVoyagerMaxRateInfo } from './voyager-info';
import { cryptoComRowInfoUS, cryptoComAdditionalInfoInt, cryptoComAdditionalInfoUSTX, cryptoComWithdrawals, makeCryptoComMaxRateInfo, cryptoComRates } from './crypto-com-info';
import { geminiRowInfoUS, geminiWithdrawals, geminiAdditionalInfoInt, geminiRates } from './gemini-info';
import { vauldRowInfoUS, vauldMaxRateInfo, vauldRates } from './vauld-info';
import { hodlnautInfoUS, hodlnautWithdrawals, hodlnautRates } from './hodlnaut-info';
import { lednRates, lednInfoInt, lednWithdrawals } from './ledn-info';
import { abraRates, abraRowInfoUS, abraMaxRateInfo, abraRowInfoInt } from './abra-info';
import { haruRates, haruInfoUS, haruWithdrawals, haruMaxRateInfo, haruAddtionalInfoMap, haruAvailabilityInfoUS } from './haru-info';
import { finbloxRowInfoInt, finbloxRates, finbloxWithdrawals } from './finblox-info';

export const specifyAPR = ' (APR)';
export const emptyCell = '-';

export const bitcoinUS = [
    {
        ...blockfiRowInfoUS,
        baseAPY: blockfiRates.newUS.BTC.base,
        maxAPY: blockfiRates.newUS.BTC.max,
        withdrawalFees: blockfiWithdrawals.BTC,
    },
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.BTC.base,
        maxAPY: celsiusRates.newUS.BTC.max + celsiusMaxRateInfoUS,
    },
    {
        ...abraRowInfoUS,
        baseAPY: abraRates.newUS.BTC.base,
        maxAPY: abraRates.newUS.BTC.max,
    },
    {
        ...hodlnautInfoUS,
        baseAPY: hodlnautRates.newUS.BTC.base,
        maxAPY: hodlnautRates.newUS.BTC.max,
        withdrawalFees: hodlnautWithdrawals.BTC,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.BTC.base,
        maxAPY: vauldRates.newUS.BTC.max + vauldMaxRateInfo,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.BTC.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.BTC.max),
        withdrawalFees: cryptoComWithdrawals.BTC,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.BTC.base,
        maxAPY: geminiRates.newUS.BTC.max,
        withdrawalFees: geminiWithdrawals.BTC,
    },
];

export const bitcoinInt = [ ...bitcoinUS ];
bitcoinInt[0] = {
    ...bitcoinUS[0],
    baseAPY: blockfiRates.newInt.BTC.base,
    maxAPY: blockfiRates.newInt.BTC.max,
    additionalInfo: emptyCell,
};

bitcoinInt[1] = {
    ...bitcoinUS[1],
    maxAPY: celsiusRates.newInt.BTC.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

bitcoinInt[2] = {
    ...bitcoinUS[2],
    ...abraRowInfoInt,
};

bitcoinInt[5] = {
    ...bitcoinUS[5],
    additionalInfo: cryptoComAdditionalInfoInt,
};

bitcoinInt[6] = {
    ...bitcoinUS[6],
    additionalInfo: geminiAdditionalInfoInt,
};

bitcoinInt.push({
    ...lednInfoInt,
    baseAPY: lednRates.newInt.BTC.base,
    maxAPY: lednRates.newInt.BTC.max,
    withdrawalFees: lednWithdrawals.BTC,
});

bitcoinInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.BTC.base,
    maxAPY: nexoRates.newInt.BTC.max,
    withdrawalFees: 'network fees; one free withdrawal per month if maintaining a ratio over 10% for NEXO tokens vs rest of portfolio'
});

bitcoinInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.BTC.base,
    maxAPY: finbloxRates.newInt.BTC.max,
    withdrawalFees: finbloxWithdrawals.BTC,
});

export const ethereumUS = [
    {
        ...blockfiRowInfoUS,
        baseAPY: blockfiRates.newUS.ETH.base,
        maxAPY: blockfiRates.newUS.ETH.max,
        withdrawalFees: blockfiWithdrawals.ETH,
    },
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.ETH.base,
        maxAPY: celsiusRates.newUS.ETH.max + celsiusMaxRateInfoUS,
    },
    {
        ...abraRowInfoUS,
        baseAPY: abraRates.newUS.ETH.base,
        maxAPY: abraRates.newUS.ETH.max,
    },
    {
        ...hodlnautInfoUS,
        baseAPY: hodlnautRates.newUS.ETH.base,
        maxAPY: hodlnautRates.newUS.ETH.max,
        withdrawalFees: hodlnautWithdrawals.ETH,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.ETH.base,
        maxAPY: vauldRates.newUS.ETH.max + vauldMaxRateInfo,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.ETH.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.ETH.max),
        withdrawalFees: cryptoComWithdrawals.ETH,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.ETH.base,
        maxAPY: geminiRates.newUS.ETH.max,
        withdrawalFees: geminiWithdrawals.ETH,
    },
];

export const ethereumInt = [ ...ethereumUS ];
ethereumInt[0] = {
    ...ethereumUS[0],
    baseAPY: blockfiRates.newInt.ETH.base,
    maxAPY: blockfiRates.newInt.ETH.max,
    additionalInfo: emptyCell,
};

ethereumInt[1] = {
    ...ethereumUS[1],
    maxAPY: celsiusRates.newInt.ETH.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

ethereumInt[2] = {
    ...ethereumUS[2],
    ...abraRowInfoInt,
};

ethereumInt[5] = {
    ...ethereumUS[5],
    additionalInfo: cryptoComAdditionalInfoInt,
};

ethereumInt[6] = {
    ...ethereumUS[6],
    additionalInfo: geminiAdditionalInfoInt,
};

ethereumInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.ETH.base,
    maxAPY: finbloxRates.newInt.ETH.max,
    withdrawalFees: finbloxWithdrawals.ETH,
});

ethereumInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.ETH.base,
    maxAPY: nexoRates.newInt.ETH.max,
    withdrawalFees: 'network fees for withdrawals on Ethereum network, free on other networks; one free Ethereum network withdrawal per month if maintaining a ratio over 10% for NEXO tokens vs rest of portfolio'
});

ethereumInt.push({
    ...lednInfoInt,
    baseAPY: lednRates.newInt.ETH.base,
    maxAPY: lednRates.newInt.ETH.max,
    withdrawalFees: lednWithdrawals.ETH,
});

export const tetherUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.USDT.base,
        maxAPY: celsiusRates.newUS.USDT.max + celsiusMaxRateInfoUS,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.USDT.base + specifyAPR,
        maxAPY: cryptoComRates.newUS.USDT.max + ' (highest rate requires locking asset for 3 months)',
        withdrawalFees: cryptoComWithdrawals.USDT,
        additionalInfo: cryptoComAdditionalInfoUSTX,
    },
    {
        ...hodlnautInfoUS,
        baseAPY: hodlnautRates.newUS.USDT.base,
        maxAPY: hodlnautRates.newUS.USDT.max,
        withdrawalFees: hodlnautWithdrawals.USDT,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.USDT.base,
        maxAPY: vauldRates.newUS.USDT.max + vauldMaxRateInfo,
    },
    {
        ...abraRowInfoUS,
        baseAPY: abraRates.newUS.USDT.base,
        maxAPY: abraRates.newUS.USDT.max,
    },
];

export const tetherInt = [ ...tetherUS ];
tetherInt[0] = {
    ...tetherUS[0],
    maxAPY: celsiusRates.newInt.USDT.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

tetherInt[1] = {
    ...tetherUS[1],
    additionalInfo: cryptoComAdditionalInfoInt,
};

tetherInt[4] = {
    ...tetherUS[4],
    ...abraRowInfoInt,
};

tetherInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.USDT.base,
    maxAPY: nexoRates.newInt.USDT.max,
});

tetherInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.USDT.base,
    maxAPY: finbloxRates.newInt.USDT.max,
    withdrawalFees: finbloxWithdrawals.USDT,
});

tetherInt.push({
    ...lednInfoInt,
    baseAPY: lednRates.newInt.USDT.base,
    maxAPY: lednRates.newInt.USDT.max,
    withdrawalFees: lednWithdrawals.USDT,
});

export const litecoinUS = [];

export const litecoinInt = [ ...litecoinUS ];

litecoinInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.LTC.base,
    maxAPY: nexoRates.newInt.LTC.max,
});

export const chainlinkUS = [];

export const chainlinkInt = [ ...chainlinkUS ];

chainlinkInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.LINK.base,
    maxAPY: nexoRates.newInt.LINK.max,
});

export const usdCoinUS = [
    {
        ...abraRowInfoUS,
        baseAPY: abraRates.newUS.USDC.base,
        maxAPY: abraRates.newUS.USDC.max,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.USDC.base + specifyAPR,
        maxAPY: cryptoComRates.newUS.USDC.max + ' (highest rate requires locking asset for 3 months)',
        withdrawalFees: cryptoComWithdrawals.USDC,
        additionalInfo: 'unavailable in TX',
    },
];

export const usdCoinInt = [ ...usdCoinUS ];

usdCoinInt[0] = {
    ...usdCoinUS[0],
    ...abraRowInfoInt,
};

usdCoinInt[1] = {
    ...usdCoinUS[1],
    additionalInfo: '-',
};

usdCoinInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.USDC.base,
    maxAPY: finbloxRates.newInt.USDC.max,
    withdrawalFees: finbloxWithdrawals.USDC,
});

usdCoinInt.push({
    ...lednInfoInt,
    baseAPY: lednRates.newInt.USDC.base,
    maxAPY: lednRates.newInt.USDC.max,
    withdrawalFees: lednWithdrawals.USDC,
});

usdCoinInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.USDC.base,
    maxAPY: nexoRates.newInt.USDC.max,
});

export const bitcoinCashUS = [];

export const bitcoinCashInt = [ ...bitcoinCashUS ];

bitcoinCashInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.BCH.base,
    maxAPY: nexoRates.newInt.BCH.max,
});

export const stellarLumensUS = [];

export const stellarLumensInt = [ ...stellarLumensUS ];

stellarLumensInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.XLM.base,
    maxAPY: nexoRates.newInt.XLM.max,
});

export const polkadotUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.DOT.base,
        maxAPY: celsiusRates.newUS.DOT.max + celsiusMaxRateInfoUS,
    },
    {
        ...abraRowInfoUS,
        baseAPY: abraRates.newUS.DOT.base,
        maxAPY: abraRates.newUS.DOT.max,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.DOT.base,
        maxAPY: vauldRates.newUS.DOT.max + vauldMaxRateInfo,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.DOT.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.DOT.max),
        withdrawalFees: cryptoComWithdrawals.DOT,
    },
];

export const polkadotInt = [ ...polkadotUS ];

polkadotInt[0] = {
    ...polkadotUS[0],
    maxAPY: celsiusRates.newInt.DOT.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

polkadotInt[1] = {
    ...polkadotUS[1],
    ...abraRowInfoInt,
};

polkadotInt[3] = {
    ...polkadotUS[3],
    additionalInfo: cryptoComAdditionalInfoInt,
};

polkadotInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.DOT.base,
    maxAPY: nexoRates.newInt.DOT.max,
});

export const polygonUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.MATIC.base,
        maxAPY: celsiusRates.newUS.MATIC.max + celsiusMaxRateInfoUS,
    },
    {
        ...abraRowInfoUS,
        baseAPY: abraRates.newUS.MATIC.base,
        maxAPY: abraRates.newUS.MATIC.max,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.MATIC.base,
        maxAPY: vauldRates.newUS.MATIC.max + vauldMaxRateInfo,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.MATIC.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.MATIC.max),
        withdrawalFees: cryptoComWithdrawals.MATIC,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.MATIC.base,
        maxAPY: geminiRates.newUS.MATIC.max,
        withdrawalFees: geminiWithdrawals.MATIC,
    },
];

export const polygonInt = [ ...polygonUS ];

polygonInt[0] = {
    ...polygonUS[0],
    maxAPY: celsiusRates.newInt.MATIC.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

polygonInt[1] = {
    ...polygonUS[1],
    ...abraRowInfoInt,
};

polygonInt[3] = {
    ...polygonUS[3],
    additionalInfo: cryptoComAdditionalInfoInt,
};

polygonInt[4] = {
    ...polygonUS[4],
    additionalInfo: geminiAdditionalInfoInt,
};

polygonInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.MATIC.base,
    maxAPY: finbloxRates.newInt.MATIC.max,
    withdrawalFees: finbloxWithdrawals.MATIC,
});

polygonInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.MATIC.base,
    maxAPY: nexoRates.newInt.MATIC.max,
});

export const decentralandUS = [
];

export const decentralandInt = [ ...decentralandUS ];

export const aaveUS = [];

export const aaveInt = [ ...aaveUS ];

aaveInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.AAVE.base,
    maxAPY: nexoRates.newInt.AAVE.max,
});

export const daiUS = [
    {
        ...blockfiRowInfoUS,
        baseAPY: blockfiRates.newUS.DAI.base,
        maxAPY: blockfiRates.newUS.DAI.max,
        withdrawalFees: blockfiWithdrawals.DAI,
    },
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.DAI.base,
        maxAPY: celsiusRates.newUS.DAI.max + celsiusMaxRateInfoUS,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.DAI.base,
        maxAPY: geminiRates.newUS.DAI.max,
        withdrawalFees: geminiWithdrawals.DAI,
    },
    {
        ...hodlnautInfoUS,
        baseAPY: hodlnautRates.newUS.DAI.base,
        maxAPY: hodlnautRates.newUS.DAI.max,
        withdrawalFees: hodlnautWithdrawals.DAI,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.DAI.base,
        maxAPY: vauldRates.newUS.DAI.max + vauldMaxRateInfo,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.DAI.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.DAI.max),
        withdrawalFees: cryptoComWithdrawals.DAI,
        additionalInfo: cryptoComAdditionalInfoUSTX,
    },
];

export const daiInt = [ ...daiUS ];
daiInt[0] = {
    ...daiUS[0],
    additionalInfo: emptyCell,
};

daiInt[1] = {
    ...daiUS[1],
    maxAPY: celsiusRates.newInt.DAI.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

daiInt[2] = {
    ...daiUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

daiInt[5] = {
    ...daiUS[5],
    additionalInfo: cryptoComAdditionalInfoInt,
};

daiInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.DAI.base,
    maxAPY: finbloxRates.newInt.DAI.max,
    withdrawalFees: finbloxWithdrawals.DAI,
});

daiInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.DAI.base,
    maxAPY: nexoRates.newInt.DAI.max,
});

export const geminiDollarUS = [
    {
        ...blockfiRowInfoUS,
        baseAPY: blockfiRates.newUS.GUSD.base,
        maxAPY: blockfiRates.newUS.GUSD.max,
        withdrawalFees: blockfiWithdrawals.GUSD,
    },
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.GUSD.base,
        maxAPY: celsiusRates.newUS.GUSD.max + celsiusMaxRateInfoUS,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.GUSD.base,
        maxAPY: geminiRates.newUS.GUSD.max,
        withdrawalFees: geminiWithdrawals.GUSD,
    },
];

export const geminiDollarInt = [ ...geminiDollarUS ];
geminiDollarInt[0] = {
    ...geminiDollarUS[0],
    baseAPY: blockfiRates.newInt.GUSD.base,
    maxAPY: blockfiRates.newInt.GUSD.max,
    additionalInfo: emptyCell,
};

geminiDollarInt[1] = {
    ...geminiDollarUS[1],
    maxAPY: celsiusRates.newInt.GUSD.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

geminiDollarInt[2] = {
    ...geminiDollarUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

export const batUS = [
];

export const batInt = [ ...batUS ];


export const uniswapUS = [
];

export const uniswapInt = [ ...uniswapUS ];

export const dogecoinUS = [];

export const dogecoinInt = [ ...dogecoinUS ];

dogecoinInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.DOGE.base,
    maxAPY: nexoRates.newInt.DOGE.max,
});

export const cardanoUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.ADA.base,
        maxAPY: celsiusRates.newUS.ADA.max + celsiusMaxRateInfoUS,
    },
    {
        ...abraRowInfoUS,
        baseAPY: abraRates.newUS.ADA.base,
        maxAPY: abraRates.newUS.ADA.max,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.ADA.base,
        maxAPY: vauldRates.newUS.ADA.max + vauldMaxRateInfo,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.ADA.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.ADA.max),
        withdrawalFees: cryptoComWithdrawals.ADA,
    },
];

export const cardanoInt = [ ...cardanoUS ];

cardanoInt[0] = {
    ...cardanoUS[0],
    maxAPY: celsiusRates.newInt.ADA.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

cardanoInt[1] = {
    ...cardanoUS[1],
    ...abraRowInfoInt,
};

cardanoInt[3] = {
    ...cardanoUS[3],
    additionalInfo: cryptoComAdditionalInfoInt,
};

cardanoInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.ADA.base,
    maxAPY: finbloxRates.newInt.ADA.max,
    withdrawalFees: finbloxWithdrawals.ADA,
});

cardanoInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.ADA.base,
    maxAPY: nexoRates.newInt.ADA.max,
});

export const eosioUS = [];

export const eosioInt = [ ...eosioUS ];

eosioInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.EOS.base,
    maxAPY: nexoRates.newInt.EOS.max,
});

export const tezosUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.XTZ.base,
        maxAPY: celsiusRates.newUS.XTZ.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.XTZ.base,
        maxAPY: vauldRates.newUS.XTZ.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.XTZ.base,
        maxAPY: geminiRates.newUS.XTZ.max,
        withdrawalFees: geminiWithdrawals.XTZ,
    },
];

export const tezosInt = [ ...tezosUS ];

tezosInt[0] = {
    ...tezosUS[0],
    maxAPY: celsiusRates.newInt.XTZ.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

tezosInt[2] = {
    ...tezosUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

tezosInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.XTZ.base,
    maxAPY: blockfiRates.newInt.XTZ.max,
    withdrawalFees: blockfiWithdrawals.XTZ,
    additionalInfo: emptyCell,
});

export const theGraphUS = [
];

export const theGraphInt = [ ...theGraphUS ];

theGraphInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.GRT.base,
    maxAPY: nexoRates.newInt.GRT.max,
});

export const compoundUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.COMP.base,
        maxAPY: celsiusRates.newUS.COMP.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.COMP.base,
        maxAPY: vauldRates.newUS.COMP.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.COMP.base,
        maxAPY: geminiRates.newUS.COMP.max,
        withdrawalFees: geminiWithdrawals.COMP,
    },
];

export const compoundInt = [ ...compoundUS ];

compoundInt[0] = {
    ...compoundUS[0],
    maxAPY: celsiusRates.newInt.COMP.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

compoundInt[2] = {
    ...compoundUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

compoundInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.COMP.base,
    maxAPY: blockfiRates.newInt.COMP.max,
    withdrawalFees: blockfiWithdrawals.COMP,
    additionalInfo: emptyCell,
});

export const makerUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.MKR.base,
        maxAPY: vauldRates.newUS.MKR.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.MKR.base,
        maxAPY: geminiRates.newUS.MKR.max,
        withdrawalFees: geminiWithdrawals.MKR,
    },
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.MKR.base,
        maxAPY: celsiusRates.newUS.MKR.max + celsiusMaxRateInfoUS,
    },
];

export const makerInt = [ ...makerUS ];

makerInt[1] = {
    ...makerUS[1],
    additionalInfo: cryptoComAdditionalInfoInt,
};

makerInt[1] = {
    ...makerUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

makerInt[2] = {
    ...makerUS[2],
    maxAPY: celsiusRates.newInt.MKR.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

makerInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.MKR.base,
    maxAPY: blockfiRates.newInt.MKR.max,
    withdrawalFees: blockfiWithdrawals.MKR,
    additionalInfo: emptyCell,
});

export const paxGoldUS = [];

export const paxGoldInt = [ ...paxGoldUS ];

paxGoldInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.PAXG.base,
    maxAPY: nexoRates.newInt.PAXG.max,
});

export const rippleUS = [
];

export const rippleInt = [ ...rippleUS ];

rippleInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.XRP.base,
    maxAPY: nexoRates.newInt.XRP.max,
});


rippleInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.XRP.base,
    maxAPY: finbloxRates.newInt.XRP.max,
    withdrawalFees: finbloxWithdrawals.XRP,
});

export const zrxUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.ZRX.base,
        maxAPY: celsiusRates.newUS.ZRX.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.ZRX.base,
        maxAPY: vauldRates.newUS.ZRX.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.ZRX.base,
        maxAPY: geminiRates.newUS.ZRX.max,
        withdrawalFees: geminiWithdrawals.ZRX,
    },
];

export const zrxInt = [ ...zrxUS ];

zrxInt[0] = {
    ...zrxUS[0],
    maxAPY: celsiusRates.newInt.ZRX.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

zrxInt[2] = {
    ...zrxUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

export const busdUS = [];

export const busdInt = [ ...busdUS ];

export const terraUS = [
];

export const terraInt = [ ...terraUS ];

terraInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.LUNA.base,
    maxAPY: blockfiRates.newInt.LUNA.max,
    withdrawalFees: blockfiWithdrawals.LUNA,
    additionalInfo: emptyCell,
});

export const solanaUS = [
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.SOL.base,
        maxAPY: geminiRates.newUS.SOL.max,
        withdrawalFees: geminiWithdrawals.SOL,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.SOL.base,
        maxAPY: vauldRates.newUS.SOL.max,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.SOL.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.SOL.max),
        withdrawalFees: cryptoComWithdrawals.SOL,
    },
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.SOL.base,
        maxAPY: celsiusRates.newUS.SOL.max + celsiusMaxRateInfoUS,
    },
];

export const solanaInt = [ ...solanaUS ];

solanaInt[0] = {
    ...solanaUS[0],
    additionalInfo: geminiAdditionalInfoInt,
};

solanaInt[2] = {
    ...solanaUS[2],
    additionalInfo: cryptoComAdditionalInfoInt,
};

solanaInt[3] = {
    ...solanaUS[3],
    maxAPY: celsiusRates.newInt.SOL.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

solanaInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.SOL.base,
    maxAPY: nexoRates.newInt.SOL.max,
});

solanaInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.SOL.base,
    maxAPY: finbloxRates.newInt.SOL.max,
    withdrawalFees: finbloxWithdrawals.SOL,
});

export const sushiSwapUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.SUSHI.base,
        maxAPY: celsiusRates.newUS.SUSHI.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.SUSHI.base,
        maxAPY: vauldRates.newUS.SUSHI.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.SUSHI.base,
        maxAPY: geminiRates.newUS.SUSHI.max,
        withdrawalFees: geminiWithdrawals.SUSHI,
    },
];

export const sushiSwapInt = [ ...sushiSwapUS ];

sushiSwapInt[0] = {
    ...sushiSwapUS[0],
    maxAPY: celsiusRates.newInt.SUSHI.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

sushiSwapInt[2] = {
    ...sushiSwapUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

sushiSwapInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.SUSHI.base,
    maxAPY: blockfiRates.newInt.SUSHI.max,
    withdrawalFees: blockfiWithdrawals.SUSHI,
    additionalInfo: emptyCell,
});

export const zCashUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.ZEC.base,
        maxAPY: celsiusRates.newUS.ZEC.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.ZEC.base,
        maxAPY: vauldRates.newUS.ZEC.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.ZEC.base,
        maxAPY: geminiRates.newUS.ZEC.max,
        withdrawalFees: geminiWithdrawals.ZEC,
    },
];

export const zCashInt = [ ...zCashUS ];

zCashInt[0] = {
    ...zCashUS[0],
    maxAPY: celsiusRates.newInt.ZEC.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

zCashInt[2] = {
    ...zCashUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

export const synthetixUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.SNX.base,
        maxAPY: celsiusRates.newUS.SNX.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.SNX.base,
        maxAPY: vauldRates.newUS.SNX.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.SNX.base,
        maxAPY: geminiRates.newUS.SNX.max,
        withdrawalFees: geminiWithdrawals.SNX,
    },
];

export const synthetixInt = [ ...synthetixUS ];

synthetixInt[0] = {
    ...synthetixUS[0],
    maxAPY: celsiusRates.newInt.SNX.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

synthetixInt[2] = {
    ...synthetixUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

export const inchUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS['1INCH'].base,
        maxAPY: celsiusRates.newUS['1INCH'].max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS['1INCH'].base,
        maxAPY: vauldRates.newUS['1INCH'].max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS['1INCH'].base,
        maxAPY: geminiRates.newUS['1INCH'].max,
        withdrawalFees: geminiWithdrawals['1INCH'],
    },
];

export const inchInt = [ ...inchUS ];

inchInt[0] = {
    ...inchUS[0],
    maxAPY: celsiusRates.newInt['1INCH'].max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

inchInt[2] = {
    ...inchUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

inchInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt['1INCH'].base,
    maxAPY: blockfiRates.newInt['1INCH'].max,
    withdrawalFees: blockfiWithdrawals['1INCH'],
    additionalInfo: emptyCell,
});

export const trueUsdUS = [];

export const trueUsdInt = [ ...trueUsdUS ];

trueUsdInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.TUSD.base,
    maxAPY: nexoRates.newInt.TUSD.max,
});

export const umaUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.UMA.base,
        maxAPY: celsiusRates.newUS.UMA.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.UMA.base,
        maxAPY: vauldRates.newUS.UMA.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.UMA.base,
        maxAPY: geminiRates.newUS.UMA.max,
        withdrawalFees: geminiWithdrawals.UMA,
    },
];

export const umaInt = [ ...umaUS ];

umaInt[0] = {
    ...umaUS[0],
    maxAPY: celsiusRates.newInt.UMA.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

umaInt[2] = {
    ...umaUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

export const kncUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.KNC.base,
        maxAPY: celsiusRates.newUS.KNC.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.KNC.base,
        maxAPY: vauldRates.newUS.KNC.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.KNC.base,
        maxAPY: geminiRates.newUS.KNC.max,
        withdrawalFees: geminiWithdrawals.KNC,
    },
];

export const kncInt = [ ...kncUS ];

kncInt[0] = {
    ...kncUS[0],
    maxAPY: celsiusRates.newInt.KNC.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

kncInt[2] = {
    ...kncUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

export const avalancheUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.AVAX.base,
        maxAPY: celsiusRates.newUS.AVAX.max + celsiusMaxRateInfoUS,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.AVAX.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.AVAX.max),
        withdrawalFees: cryptoComWithdrawals.AVAX,
    },
];

export const avalancheInt = [ ...avalancheUS ];

avalancheInt[0] = {
    ...avalancheUS[0],
    maxAPY: celsiusRates.newInt.AVAX.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

avalancheInt[1] = {
    ...avalancheUS[1],
    baseAPY: cryptoComRates.newInt.AVAX.base,
    maxAPY: cryptoComRates.newInt.AVAX.max,
    additionalInfo: cryptoComAdditionalInfoInt,
};

avalancheInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.AVAX.base,
    maxAPY: nexoRates.newInt.AVAX.max,
});

export const omgUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.OMG.base,
        maxAPY: celsiusRates.newUS.OMG.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.OMG.base,
        maxAPY: vauldRates.newUS.OMG.max + vauldMaxRateInfo,
    },
];

export const omgInt = [ ...omgUS ];

omgInt[0] = {
    ...omgUS[0],
    maxAPY: celsiusRates.newInt.OMG.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

omgInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.OMG.base,
    maxAPY: blockfiRates.newInt.OMG.max,
    withdrawalFees: blockfiWithdrawals.OMG,
    additionalInfo: emptyCell,
});

export const shibaInuUS = [];

export const shibaInuInt = [ ...shibaInuUS ];

shibaInuInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.SHIB.base,
    maxAPY: finbloxRates.newInt.SHIB.max,
    withdrawalFees: finbloxWithdrawals.SHIB,
});

export const binanceCoinUS = [
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.BNB.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.BNB.max),
        withdrawalFees: cryptoComWithdrawals.BNB,
    },
];

export const binanceCoinInt = [ ...binanceCoinUS ];

binanceCoinInt[0] = {
    ...binanceCoinUS[0],
    additionalInfo: cryptoComAdditionalInfoInt,
};

binanceCoinInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.BNB.base,
    maxAPY: nexoRates.newInt.BNB.max,
});

binanceCoinInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.BNB.base,
    maxAPY: finbloxRates.newInt.BNB.max,
    withdrawalFees: finbloxWithdrawals.BNB,
});

export const etcUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.ETC.base,
        maxAPY: celsiusRates.newUS.ETC.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.ETC.base,
        maxAPY: vauldRates.newUS.ETC.max + vauldMaxRateInfo,
    },
];

export const etcInt = [ ...etcUS ];

etcInt[0] = {
    ...etcUS[0],
    maxAPY: celsiusRates.newInt.ETC.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

export const bancorUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.BNT.base,
        maxAPY: celsiusRates.newUS.BNT.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.BNT.base,
        maxAPY: vauldRates.newUS.BNT.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.BNT.base,
        maxAPY: geminiRates.newUS.BNT.max,
        withdrawalFees: geminiWithdrawals.BNT,
    },
];

export const bancorInt = [ ...bancorUS ];

bancorInt[0] = {
    ...bancorUS[0],
    maxAPY: celsiusRates.newInt.BNT.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

bancorInt[2] = {
    ...bancorUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

export const cosmosUs = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.ATOM.base,
        maxAPY: vauldRates.newUS.ATOM.max + vauldMaxRateInfo,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.ATOM.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.ATOM.max),
        withdrawalFees: cryptoComWithdrawals.ATOM,
    },
];

export const cosmosInt = [ ...cosmosUs ];

cosmosInt[1] = {
    ...cosmosUs[1],
    additionalInfo: cryptoComAdditionalInfoInt,
};

cosmosInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.ATOM.base,
    maxAPY: nexoRates.newInt.ATOM.max,
});

export const orchidUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.OXT.base,
        maxAPY: vauldRates.newUS.OXT.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.OXT.base,
        maxAPY: geminiRates.newUS.OXT.max,
        withdrawalFees: geminiWithdrawals.OXT,
    },
];

export const orchidInt = [ ...orchidUS ];

orchidInt[1] = {
    ...orchidUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

export const filecoinUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.FIL.base,
        maxAPY: vauldRates.newUS.FIL.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.FIL.base,
        maxAPY: geminiRates.newUS.FIL.max,
        withdrawalFees: geminiWithdrawals.FIL,
    },
];

export const filecoinInt = [ ...filecoinUS ];

filecoinInt[1] = {
    ...filecoinUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

filecoinInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.FIL.base,
    maxAPY: blockfiRates.newInt.FIL.max,
    withdrawalFees: blockfiWithdrawals.FIL,
    additionalInfo: emptyCell,
});

export const fantomUS = [
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.FTM.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.FTM.max),
        withdrawalFees: cryptoComWithdrawals.FTM,
    },
];

export const fantomInt = [ ...fantomUS ];

fantomInt[0] = {
    ...fantomUS[0],
    additionalInfo: cryptoComAdditionalInfoInt,
};

fantomInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.FTM.base,
    maxAPY: nexoRates.newInt.FTM.max,
});

export const axieUS = [];

export const axieInt = [ ...axieUS ];

axieInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.AXS.base,
    maxAPY: finbloxRates.newInt.AXS.max,
    withdrawalFees: finbloxWithdrawals.AXS,
});

axieInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.AXS.base,
    maxAPY: nexoRates.newInt.AXS.max,
});

export const paxDollarUS = [];

export const paxDollarInt = [ ...paxDollarUS ];

paxDollarInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.USDP.base,
    maxAPY: nexoRates.newInt.USDP.max,
});

export const terraUsdUS = [
];

export const terraUsdInt = [ ...terraUsdUS ];

export const apeCoinUS = [];

export const apeCoinInt = [ ...apeCoinUS ];

apeCoinInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.APE.base,
    maxAPY: nexoRates.newInt.APE.max,
});

export const nearUS = [
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.NEAR.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.NEAR.max),
        withdrawalFees: cryptoComWithdrawals.NEAR,
    },
];

export const nearInt = [ ...nearUS ];

nearInt[0] = {
    ...nearUS[0],
    additionalInfo: cryptoComAdditionalInfoInt,
};

nearInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.NEAR.base,
    maxAPY: nexoRates.newInt.NEAR.max,
});

export const algorandUS = [
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.ALGO.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.ALGO.max),
        withdrawalFees: cryptoComWithdrawals.ALGO,
    },
    {
        ...blockfiRowInfoUS,
        baseAPY: blockfiRates.newUS.ALGO.base,
        maxAPY: blockfiRates.newUS.ALGO.max,
        withdrawalFees: blockfiWithdrawals.ALGO,
    },
];

export const algorandInt = [ ...algorandUS ];

algorandInt[0] = {
    ...algorandUS[0],
    additionalInfo: cryptoComAdditionalInfoInt,
};

algorandInt[1] = {
    ...algorandUS[1],
    additionalInfo: emptyCell,
};

export const curveUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.CRV.base,
        maxAPY: celsiusRates.newUS.CRV.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.CRV.base,
        maxAPY: vauldRates.newUS.CRV.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.CRV.base,
        maxAPY: geminiRates.newUS.CRV.max,
        withdrawalFees: geminiWithdrawals.CRV,
    },
];

export const curveInt = [ ...curveUS ];

curveInt[0] = {
    ...curveUS[0],
    maxAPY: celsiusRates.newInt.CRV.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

curveInt[2] = {
    ...curveUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

curveInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.CRV.base,
    maxAPY: blockfiRates.newInt.CRV.max,
    withdrawalFees: blockfiWithdrawals.CRV,
    additionalInfo: emptyCell,
});

export const yearnUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.YFI.base,
        maxAPY: celsiusRates.newUS.YFI.max + celsiusMaxRateInfoUS,
    },
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.YFI.base,
        maxAPY: vauldRates.newUS.YFI.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.YFI.base,
        maxAPY: geminiRates.newUS.YFI.max,
        withdrawalFees: geminiWithdrawals.YFI,
    },
];

export const yearnInt = [ ...yearnUS ];

yearnInt[0] = {
    ...yearnUS[0],
    maxAPY: celsiusRates.newInt.YFI.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

yearnInt[2] = {
    ...yearnUS[2],
    additionalInfo: geminiAdditionalInfoInt,
};

yearnInt.push({
    ...blockfiRowInfoUS,
    baseAPY: blockfiRates.newInt.YFI.base,
    maxAPY: blockfiRates.newInt.YFI.max,
    withdrawalFees: blockfiWithdrawals.YFI,
    additionalInfo: emptyCell,
});

export const wrappedBitcoinUS = [
    {
        ...celsiusRowInfoUS,
        baseAPY: celsiusRates.newUS.WBTC.base,
        maxAPY: celsiusRates.newUS.WBTC.max + celsiusMaxRateInfoUS,
    },
    {
        ...hodlnautInfoUS,
        baseAPY: hodlnautRates.newUS.WBTC.base,
        maxAPY: hodlnautRates.newUS.WBTC.max,
        withdrawalFees: hodlnautWithdrawals.WBTC,
    },
];

export const wrappedBitcoinInt = [ ...wrappedBitcoinUS ];

wrappedBitcoinInt[0] = {
    ...wrappedBitcoinUS[0],
    maxAPY: celsiusRates.newInt.WBTC.max + celsiusMaxRateInfo,
    additionalInfo: emptyCell,
};

export const ampUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.AMP.base,
        maxAPY: vauldRates.newUS.AMP.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.AMP.base,
        maxAPY: geminiRates.newUS.AMP.max,
        withdrawalFees: geminiWithdrawals.AMP,
    },
];

export const ampInt = [ ...ampUS ];

ampInt[1] = {
    ...ampUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

export const flowUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.FLOW.base,
        maxAPY: vauldRates.newUS.FLOW.max + vauldMaxRateInfo,
    },
];

export const flowInt = [ ...flowUS ];

export const ankrUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.ANKR.base,
        maxAPY: vauldRates.newUS.ANKR.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.ANKR.base,
        maxAPY: geminiRates.newUS.ANKR.max,
        withdrawalFees: geminiWithdrawals.ANKR,
    },
];

export const ankrInt = [ ...ankrUS ];

ankrInt[1] = {
    ...ankrUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

export const balancerUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.BAL.base,
        maxAPY: vauldRates.newUS.BAL.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.BAL.base,
        maxAPY: geminiRates.newUS.BAL.max,
        withdrawalFees: geminiWithdrawals.BAL,
    },
];

export const balancerInt = [ ...balancerUS ];

balancerInt[1] = {
    ...balancerUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

export const tronUS = [];

export const tronInt = [ ...tronUS ];

tronInt.push({
    ...nexoRowInfoInt,
    baseAPY: nexoRates.newInt.TRX.base,
    maxAPY: nexoRates.newInt.TRX.max,
});

export const elrondUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.EGLD.base,
        maxAPY: vauldRates.newUS.EGLD.max + vauldMaxRateInfo,
    },
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.EGLD.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.EGLD.max),
        withdrawalFees: cryptoComWithdrawals.EGLD,
    },
];

export const elrondInt = [ ...elrondUS ];

elrondInt[1] = {
    ...elrondUS[1],
    additionalInfo: cryptoComAdditionalInfoInt,
};

export const enjinUS = [
];

export const enjinInt = [ ...enjinUS ];

export const livepeerUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.LPT.base,
        maxAPY: vauldRates.newUS.LPT.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.LPT.base,
        maxAPY: geminiRates.newUS.LPT.max,
        withdrawalFees: geminiWithdrawals.LPT,
    },
];

export const livepeerInt = [ ...livepeerUS ];

livepeerInt[1] = {
    ...livepeerUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

export const storjUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.STORJ.base,
        maxAPY: vauldRates.newUS.STORJ.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.STORJ.base,
        maxAPY: geminiRates.newUS.STORJ.max,
        withdrawalFees: geminiWithdrawals.STORJ,
    },
];

export const storjInt = [ ...storjUS ];

storjInt[1] = {
    ...storjUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

export const injectiveUS = [
    {
        ...vauldRowInfoUS,
        baseAPY: vauldRates.newUS.INJ.base,
        maxAPY: vauldRates.newUS.INJ.max + vauldMaxRateInfo,
    },
    {
        ...geminiRowInfoUS,
        baseAPY: geminiRates.newUS.INJ.base,
        maxAPY: geminiRates.newUS.INJ.max,
        withdrawalFees: geminiWithdrawals.INJ,
    },
];

export const injectiveInt = [ ...injectiveUS ];

injectiveInt[1] = {
    ...injectiveUS[1],
    additionalInfo: geminiAdditionalInfoInt,
};

export const smoothLovePotionUS = [];

export const smoothLovePotionInt = [ ...smoothLovePotionUS ];

smoothLovePotionInt.push({
    ...finbloxRowInfoInt,
    baseAPY: finbloxRates.newInt.SLP.base,
    maxAPY: finbloxRates.newInt.SLP.max,
    withdrawalFees: finbloxWithdrawals.SLP,
});

export const harmonyUS = [
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.ONE.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.ONE.max),
        withdrawalFees: cryptoComWithdrawals.ONE,
    },
];

export const harmonyInt = [ ...harmonyUS ];

harmonyInt[0] = {
    ...harmonyUS[0],
    additionalInfo: cryptoComAdditionalInfoInt,
};
export const zilliqaUS = []

export const zilliqaInt = [
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newInt.ZIL.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newInt.ZIL.max),
        withdrawalFees: cryptoComWithdrawals.ZIL,
        additionalInfo: cryptoComAdditionalInfoInt,
    },
];

export const vechainUS = [
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.VET.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.VET.max),
        withdrawalFees: cryptoComWithdrawals.VET,
    },
];

export const vechainInt = [ ...vechainUS ];

vechainInt[0] = {
    ...vechainUS[0],
    additionalInfo: cryptoComAdditionalInfoInt,
};

export const cronosUS = [
    {
        ...cryptoComRowInfoUS,
        baseAPY: cryptoComRates.newUS.CRO.base + specifyAPR,
        maxAPY: makeCryptoComMaxRateInfo(cryptoComRates.newUS.CRO.max),
        withdrawalFees: cryptoComWithdrawals.CRO,
    },
];

export const cronosInt = [ ...cronosUS ];

cronosInt[0] = {
    ...cronosUS[0],
    additionalInfo: cryptoComAdditionalInfoInt,
};

export const pathnameToCurrencyUSMap  = {
    [slugBTC]: bitcoinUS,
    [slugETH]: ethereumUS,
    [slugUSDT]: tetherUS,
    [slugLTC]: litecoinUS,
    [slugLINK]: chainlinkUS,
    [slugUSDC]: usdCoinUS,
    [slugBCH]: bitcoinCashUS,
    [slugXLM]: stellarLumensUS,
    [slugDOT]: polkadotUS,
    [slugMATIC]: polygonUS,
    [slugMANA]: decentralandUS,
    [slugAAVE]: aaveUS,
    [slugDAI]: daiUS,
    [slugGUSD]: geminiDollarUS,
    [slugBAT]: batUS,
    [slugUNI]: uniswapUS,
    [slugDOGE]: dogecoinUS,
    [slugADA]: cardanoUS,
    [slugEOS]: eosioUS,
    [slugXTZ]: tezosUS,
    [slugGRT]: theGraphUS,
    [slugCOMP]: compoundUS,
    [slugMKR]: makerUS,
    [slugPAXG]: paxGoldUS,
    [slugXRP]: rippleUS,
    [slugZRX]: zrxUS,
    // [slugBUSD]: busdUS,
    // [slugLUNA]: terraUS,
    [slugSOL]: solanaUS,
    [slugSUSHI]: sushiSwapUS,
    [slugZEC]: zCashUS,
    [slugSNX]: synthetixUS,
    [slug1INCH]: inchUS,
    [slugTUSD]: trueUsdUS,
    [slugUMA]: umaUS,
    [slugKNC]: kncUS,
    [slugAVAX]: avalancheUS,
    [slugOMG]: omgUS,
    [slugSHIB]: shibaInuUS,
    [slugBNB]: binanceCoinUS,
    [slugETC]: etcUS,
    [slugBNT]: bancorUS,
    [slugATOM]: cosmosUs,
    [slugOXT]: orchidUS,
    [slugFIL]: filecoinUS,
    [slugFTM]: fantomUS,
    [slugAXS]: axieUS,
    [slugUSDP]: paxDollarUS,
    // [slugUST]: terraUsdUS,
    [slugAPE]: apeCoinUS,
    [slugNEAR]: nearUS,
    [slugCRV]: curveUS,
    [slugYFI]: yearnUS,
    [slugALGO]: algorandUS,
    [slugWBTC]: wrappedBitcoinUS,
    [slugAMP]: ampUS,
    [slugFLOW]: flowUS,
    // [slugCELR]: celerUS,
    [slugANKR]: ankrUS,
    [slugBAL]: balancerUS,
    [slugTRX]: tronUS,
    [slugEGLD]: elrondUS,
    [slugENJ]: enjinUS,
    [slugLPT]: livepeerUS,
    [slugSTORJ]: storjUS,
    [slugINJ]: injectiveUS,
    // [slugUSDD]: usddUS,
    [slugSLP]: smoothLovePotionUS,
    [slugONE]: harmonyUS,
    [slugZIL]: zilliqaUS,
    [slugVET]: vechainUS,
    // [slugRON]: roninUS,
    [slugCRO]: cronosUS,
};

export const pathnameToCurrencyIntMap  = {
    [slugBTC]: bitcoinInt,
    [slugETH]: ethereumInt,
    [slugUSDT]: tetherInt,
    [slugLTC]: litecoinInt,
    [slugLINK]: chainlinkInt,
    [slugUSDC]: usdCoinInt,
    [slugBCH]: bitcoinCashInt,
    [slugXLM]: stellarLumensInt,
    [slugDOT]: polkadotInt,
    [slugMATIC]: polygonInt,
    [slugMANA]: decentralandInt,
    [slugAAVE]: aaveInt,
    [slugDAI]: daiInt,
    [slugGUSD]: geminiDollarInt,
    [slugBAT]: batInt,
    [slugUNI]: uniswapInt,
    [slugDOGE]: dogecoinInt,
    [slugADA]: cardanoInt,
    [slugEOS]: eosioInt,
    [slugXTZ]: tezosInt,
    [slugGRT]: theGraphInt,
    [slugCOMP]: compoundInt,
    [slugMKR]: makerInt,
    [slugPAXG]: paxGoldInt,
    [slugXRP]: rippleInt,
    [slugZRX]: zrxInt,
    // [slugBUSD]: busdInt,
    // [slugLUNA]: terraInt,
    [slugSOL]: solanaInt,
    [slugSUSHI]: sushiSwapInt,
    [slugZEC]: zCashInt,
    [slugSNX]: synthetixInt,
    [slug1INCH]: inchInt,
    [slugTUSD]: trueUsdInt,
    [slugUMA]: umaInt,
    [slugKNC]: kncInt,
    [slugAVAX]: avalancheInt,
    [slugOMG]: omgInt,
    [slugSHIB]: shibaInuInt,
    [slugBNB]: binanceCoinInt,
    [slugETC]: etcInt,
    [slugBNT]: bancorInt,
    [slugATOM]: cosmosInt,
    [slugOXT]: orchidInt,
    [slugFIL]: filecoinInt,
    [slugFTM]: fantomInt,
    [slugAXS]: axieInt,
    [slugUSDP]: paxDollarInt,
    // [slugUST]: terraUsdInt,
    [slugAPE]: apeCoinInt,
    [slugNEAR]: nearInt,
    [slugCRV]: curveInt,
    [slugYFI]: yearnInt,
    [slugALGO]: algorandInt,
    [slugWBTC]: wrappedBitcoinInt,
    [slugAMP]: ampInt,
    [slugFLOW]: flowInt,
    // [slugCELR]: celerInt,
    [slugANKR]: ankrInt,
    [slugBAL]: balancerInt,
    [slugTRX]: tronInt,
    [slugEGLD]: elrondInt,
    [slugENJ]: enjinInt,
    [slugLPT]: livepeerInt,
    [slugSTORJ]: storjInt,
    [slugINJ]: injectiveInt,
    // [slugUSDD]: usddInt,
    [slugSLP]: smoothLovePotionInt,
    [slugONE]: harmonyInt,
    [slugZIL]: zilliqaInt,
    [slugVET]: vechainInt,
    // [slugRON]: roninInt,
    [slugCRO]: cronosInt,
};
