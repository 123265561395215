export const haruDateEffective = '6/1/23';

export const ratesUS = {
    BTC: {
        base: '4.00%',
        max: '11.00%',
    },
    ETH: {
        base: '4.10%',
        max: '10.50%',
    },
    USDT: {
        base: '6.10%',
        max: '12.00%',
    },
    XRP: {
        base: '3.60%',
        max: '10.00%',
    },
    USDC: {
        base: '4.50%',
        max: '11.00%',
    },
};

export const ratesInt = ratesUS;
