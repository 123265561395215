import React from 'react';
import { Dialog } from 'evergreen-ui';
import { graphql, useStaticQuery } from 'gatsby'

const ReferralModal = ({ platform, isShown, setIsShown }) => {
    const data = useStaticQuery(graphql`
        query {
            allGhostPage(filter: {custom_template: {eq: "custom-referral-code-page"}}) {
            edges {
                node {
                    html
                    slug
                    }
                }
            }
        }
    `);
    const referralPagesMap = {};
    data.allGhostPage.edges.forEach((item) => {
        const { slug, html } = item.node;
        referralPagesMap[slug] = html;
    });
    const title = <><img width="32" height="32" src={platform.icon} alt={platform.name + ' logo'} />&nbsp;&nbsp;{platform.name}</>
    return (
          <Dialog
            isShown={isShown}
            title={title}
            // title={`${platform.name} Sign Up Bonus Details`}
            onCloseComplete={() => setIsShown(false)}
            preventBodyScrolling={true}
            hasFooter={false}
          >
            <div className="referral-info" dangerouslySetInnerHTML={{ __html: referralPagesMap[platform.referralSlug] }}></div>
          </Dialog>
      )
}

export default ReferralModal;
