import React from 'react';

const maxBitcoinAPY = '4.75% (3.25% in kind + 1.5% in VGX) for 0.01 to 0.25 BTC';
const bitcoinAPY = <div>3.25% for 0.01 to 0.25 BTC <br />1.75% for 0.25 to 2 BTC <br />1.05% for over 2 BTC</div>;

const maxEthAPY = '5.55% (4.05% in kind + 1.5% in VGX) for 0.5 to 20 ETH';
const ethAPY = <div>4.05% for 0.5 to 20 ETH <br />3% for 20 to 50 ETH <br />1.5% for over 50 ETH</div>;

const maxUSDCoinAPY = '10.5% (9% in kind + 1.5% in VGX) for 100 to 25k USDC';
const USDCoinAPY = <div>9% for 100 to 25k USDC <br />7% for 25k to 100k USDC <br />6% for over 100k USDC</div>;

const VGXRequirement = ' - requires holding 20,000 VGX in your portfolio';

export const voyagerDateEffective = '6/1/22';

export const ratesUS = {
    DOT: {
        base: '10.00%',
        max: '10.00%',
    },
    USDC: {
        base: USDCoinAPY,
        max: maxUSDCoinAPY + VGXRequirement,
    },
    VGX: {
        base: '7.00%',
        max: '7.00%',
    },
    BTC: {
        base: bitcoinAPY,
        max: maxBitcoinAPY + VGXRequirement,
    },
    MATIC: {
        base: '3.00%',
        max: '3.00%',
    },
    ADA: {
        base: '3.00%',
        max: '3.00%',
    },
    LTC: {
        base: '2.50%',
        max: '2.50%',
    },
    ETH: {
        base: ethAPY,
        max: maxEthAPY + VGXRequirement,
    },
    LUNA: {
        base: 'no longer available',
        max: '',
    },
    LINK: {
        base: '2.00%',
        max: '2.00%',
    },
    ATOM: {
        base: '2.00%',
        max: '2.00%',
    },
    CELO: {
        base: '3.00%',
        max: '3.00%',
    },
    AAVE: {
        base: '3.00%',
        max: '3.00%',
    },
    COMP: {
        base: '3.00%',
        max: '3.00%',
    },
    DASH: {
        base: '3.00%',
        max: '3.00%',
    },
    DOGE: {
        base: '0.50%',
        max: '0.50%',
    },
    UNI: {
        base: '2.00%',
        max: '2.00%',
    },
    SHIB: {
        base: '0.50%',
        max: '0.50%',
    },
    BCH: {
        base: '2.00%',
        max: '2.00%',
    },
    GRT: {
        base: '2.00%',
        max: '2.00%',
    },
    FIL: {
        base: '2.00%',
        max: '2.00%',
    },
    XTZ: {
        base: '2.00%',
        max: '2.00%',
    },
    EOS: {
        base: '1.00%',
        max: '1.00%',
    },
    MANA: {
        base: '0.50%',
        max: '0.50%',
    },
    UMA: {
        base: '1.00%',
        max: '1.00%',
    },
    ZRX: {
        base: '1.50%',
        max: '1.50%',
    },
    STMX: {
        base: '1.00%',
        max: '1.00%',
    },
    ETC: {
        base: '1.50%',
        max: '1.50%',
    },
    OXT: {
        base: '1.00%',
        max: '1.00%',
    },
    XLM: {
        base: '1.00%',
        max: '1.00%',
    },
    OMG: {
        base: '0.50%',
        max: '0.50%',
    },
    ZEC: {
        base: '1.00%',
        max: '1.00%',
    },
    BAT: {
        base: '1.00%',
        max: '1.00%',
    },
    KNC: {
        base: '0.50%',
        max: '0.50%',
    },
    ALGO: {
        base: '2.00%',
        max: '2.00%',
    },
    SOL: {
        base: '3.00%',
        max: '3.00%',
    },
    APE: {
        base: '8.00%',
        max: '8.00%',
    },
    KAVA: {
        base: '12.00%',
        max: '12.00%',
    },
    KSM: {
        base: '8.00%',
        max: '8.00%',
    },
    AVAX: {
        base: '3.00%',
        max: '3.00%',
    },
};


export const ratesInt = {};
