import React from 'react';
export const lednDateEffective = '5/1/24';

const bitcoinAPY = <div>1% on first 2 BTC <br />2.5% for over 2 BTC</div>;
const ethereumAPY = <div>3% on first 50 ETH <br />4% for over 50 ETH</div>;
const usdCoinAPY = <div>9.5% on first 100k USDC <br />10.5% for over 100K USDC</div>;
const tetherAPY = <div>9.5% on first 100k USDT <br />10.5% for over 100K USDT</div>;

export const ratesUS = {};

export const ratesInt = {
    BTC: {
        base: bitcoinAPY,
        max: '2.50%',
    },
    USDC: {
        base: usdCoinAPY,
        max: '10.50%',
    },
    USDT: {
        base: tetherAPY,
        max: '10.50%',
    },
    ETH: {
        base: ethereumAPY,
        max: '4.00%',
    },
};
