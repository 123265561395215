import React from 'react';
import { Popover, Menu, Position, Button, CaretDownIcon, TextDropdownButton, Pane } from 'evergreen-ui';
import { currencies4Split, symbolToCurrencyMap } from '../../utils/constants';
import { NavDropdownLink, DropdownNav } from '.'

const RatesByCurrencyNav = () => {
    const popoverContent = (
        <Pane maxHeight="80vh" overflow="auto" display="flex">
            {currencies4Split.map((column) => (
                <Menu>
                    <Menu.Group>
                        {column.map((symbol) => (
                            <NavDropdownLink slug={symbolToCurrencyMap[symbol].slug} imageUrl={symbolToCurrencyMap[symbol].imageUrl} label={symbol} />
                        ))}
                    </Menu.Group>
                </Menu>
            ))}
        </Pane>
    );

    return <DropdownNav popoverContent={popoverContent}>Rates by Currency</DropdownNav>;
};

export default RatesByCurrencyNav;
