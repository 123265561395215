import React from 'react';
import { Table, Tooltip, Position, InfoSignIcon, Popover, Pane, Paragraph } from 'evergreen-ui';

const InfoPopover = ({ content }) => {
    const popoverContent = (
        <Pane width={280} padding={16}>
            {content}
        </Pane>
    );

    return <Popover content={popoverContent} position={Position.TOP}><InfoSignIcon size={16} cursor="pointer" /></Popover>;
};

export default InfoPopover;

