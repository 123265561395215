import React, { useState } from 'react';
import { NavDropdownLink } from '.';
import { Pane, Strong, ChevronRightIcon, ChevronDownIcon, Menu } from 'evergreen-ui';

const ExpandableList = ({ item: { label, children, map, splitColumns }, checkPath }) => {
    const [isMinimized, setMinimized] = useState(true);
    return (
        <Pane marginY={20} >
            <Pane display="flex" alignItems="center" justifyContent="space-between" onClick={() => setMinimized(!isMinimized)}>
                <Strong fontSize={18}>{label}</Strong>
                {isMinimized && <ChevronRightIcon size={24} />}
                {!isMinimized && <ChevronDownIcon size={24} />}
            </Pane>
            <Pane display="flex" justifyContent="space-around">
                {!isMinimized && splitColumns && children.map((column) => (
                    <Menu>
                        <Menu.Group>
                            {column.map((child) => {
                                let { slug, imageUrl } = map[child];
                                return (
                                    <NavDropdownLink 
                                        onClick={() => checkPath(slug)}
                                        paddingY={20}
                                        marginTop={10}
                                        slug={slug}
                                        label={child}
                                        imageUrl={imageUrl}
                                    />
                            )})}
                        </Menu.Group>
                    </Menu>
                ))}
            </Pane>
            {!isMinimized && !splitColumns && (<Menu>
                <Menu.Group>
                    {children.map((child) => {
                        const navItem = map ? map[child] : child;
                        let { slug, name, imageUrl, versusPath } = navItem;
                        let displayName = name;
                        if (label === 'Platform vs Platform') {
                            displayName = name + ' Rates vs';
                            slug = versusPath; 
                        }
                        return (
                            <NavDropdownLink 
                                onClick={() => checkPath(slug)}
                                paddingY={20}
                                marginTop={10}
                                slug={slug}
                                label={displayName}
                                imageUrl={imageUrl}
                            />
                    )})}
                </Menu.Group>
            </Menu>)}
        </Pane>
    );
};

export default ExpandableList;
