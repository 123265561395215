import React from 'react';
import { Popover, Menu, Position, Button, CaretDownIcon, TextDropdownButton, Pane } from 'evergreen-ui';

const DropdownNav = ({ popoverContent, children }) => {
    const [showPopover, updateShowPopover] = React.useState(false);

    return (
        <Pane onClick={() => updateShowPopover(!showPopover)} className="site-nav-item dropdown-button-container" onMouseLeave={() => updateShowPopover(false)}>
            <Popover isShown={showPopover} content={popoverContent} position={Position.BOTTOM_RIGHT} >
                <TextDropdownButton onMouseOver={() => updateShowPopover(true)} icon={<CaretDownIcon color="white" />} size="large">
                    {children}
                </TextDropdownButton>
            </Popover>
        </Pane>
    );
};

export default DropdownNav;