import React from 'react';
import { Table, Text, Pane } from 'evergreen-ui';
import { Link } from '.'

const AllDeveloperActivityTable = ({ rows, columnTitle }) => {
    return (
        <Table width={300} marginX="auto" marginBottom="1.5em">
            <Table.Head position="sticky" top={-1}>
                <Table.TextHeaderCell flex={1}>Blockchain</Table.TextHeaderCell>
                <Table.TextHeaderCell flex={2}>{columnTitle}</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
                {rows.map(({ currency, number, slug }) => (
                    <Table.Row isSelectable height="auto" paddingY={12}>
                        <Table.TextCell flex={1}><Link to={slug}>{currency}</Link></Table.TextCell>
                        <Table.TextCell flex={2}>{number.toLocaleString(undefined, {maximumFractionDigits: 0})}</Table.TextCell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}

export default AllDeveloperActivityTable;
