import React from 'react';

const maxBlockfiBitcoinAPY = '3% on first 0.1 BTC';
const blockfiBitcoinAPY = <div>{maxBlockfiBitcoinAPY} <br />1% for 0.1 to 0.35 BTC <br />0.1% for over 0.35 BTC</div>;

const maxBlockfiEthAPY = '3% on first 1.5 ETH';
const blockfiEthAPY = <div>{maxBlockfiEthAPY} <br />1.5% for 1.5 to 5 ETH <br />0.25% for over 5 ETH</div>;

const maxBlockfiLitecoinAPY = '2% on first 20 LTC';
const blockfiLitecoinAPY = <div>{maxBlockfiLitecoinAPY} <br />1% for 20 to 100 LTC <br />0.1% for over 100 LTC</div>;

const maxblockfiChainlinkAPY = '1% on first 100 LINK';
const blockfiChainlinkAPY = <div>{maxblockfiChainlinkAPY} <br />0.2% for 100 to 500 LINK <br />0.1% for over 500 LINK</div>;

const maxblockfiPaxosGoldAPY = '1% on first 1.5 PAXG';
const blockfiPaxosGoldAPY = <div>{maxblockfiPaxosGoldAPY} <br />0.2% for 1.5 to 5 PAXG <br />0.1% for over 5 PAXG</div>;

const maxBlockfiUniswapAPY = '1% on first 100 UNI';
const blockfiUniswapAPY = <div>{maxBlockfiUniswapAPY} <br />0.2% for 100 to 500 UNI <br />0.1% for over 500 UNI</div>;

const blockfiBatAPY = <div>1.00% on first 4k BAT <br />0.2% for 4k to 20k BAT <br />0.1% for over 20k BAT</div>;

const maxBlockfiUSDCoinAPY = '7% on first 20k USDC'
const blockfiUSDCoinAPY = <div>{maxBlockfiUSDCoinAPY} <br />5% for 20k to 2M USDC<br />4% for over 2M USDC</div>;

const maxBlockfiGeminiDollarsAPY = '7% on first 20k GUSD'
const blockfiGeminiDollarsAPY = <div>{maxBlockfiGeminiDollarsAPY} <br />5% for 20k to 2M GUSD<br />4% for over 2M GUSD</div>;

const maxBlockfiPaxosAPY = '7% on first 20k PAX'
const blockfiPaxosAPY = <div>{maxBlockfiPaxosAPY} <br />5% for 20k to 2M PAX<br />4% for over 2M PAX</div>;

const maxBlockfiTetherAPY = '7.25% on first 20k USDT'
const blockfiTetherAPY = <div>{maxBlockfiTetherAPY} <br />6% for 20k to 5M USDT<br />4.5% for over 5M USDT</div>;

const maxBlockfiBinanceUSDAPY = '7% on first 20k BUSD'
const blockfiBinanceUSDAPY = <div>{maxBlockfiBinanceUSDAPY} <br />5% for 20k to 2M BUSD<br />4% for over 2M BUSD</div>;

const maxBlockfiDaiAPY = '6% on first 20k DAI'
const blockfiDaiAPY = <div>{maxBlockfiDaiAPY} <br />4% for 20k to 2M DAI<br />3.5% for over 2M DAI</div>;

export const blockfiDateEffective = '6/1/22';

export const ratesUS = {
    'BTC': {
        base: blockfiBitcoinAPY,
        max: maxBlockfiBitcoinAPY,
    },
    'ETH': {
        base: blockfiEthAPY,
        max: maxBlockfiEthAPY,
    },
    'LTC': {
        base: blockfiLitecoinAPY,
        max: maxBlockfiLitecoinAPY,
    },
    'LINK': {
        base: blockfiChainlinkAPY,
        max: maxblockfiChainlinkAPY,
    },
    'USDC': {
        base: blockfiUSDCoinAPY,
        max: maxBlockfiUSDCoinAPY,
    },
    'GUSD': {
        base: blockfiGeminiDollarsAPY,
        max: maxBlockfiGeminiDollarsAPY,
    },
    'PAX': {
        base: blockfiPaxosAPY,
        max: maxBlockfiPaxosAPY,
    },
    'PAXG': {
        base: blockfiPaxosGoldAPY,
        max: maxblockfiPaxosGoldAPY,
    },
    'BUSD': {
        base: blockfiBinanceUSDAPY,
        max: maxBlockfiBinanceUSDAPY,
    },
    'DAI': {
        base: blockfiDaiAPY,
        max: maxBlockfiDaiAPY,
    },
    'UNI': {
        base: blockfiUniswapAPY,
        max: maxBlockfiUniswapAPY,
    },
    'BAT': {
        base: blockfiBatAPY,
        max: '1.00% on first 4k BAT',
    },
    'DOGE': {
        base: '2.00%', // BlockFi never announced DOGE, ALGO, BCH in the US, but they show in the US on the rates page, so adding them to match
        max: '2.00%',
    },
    'ALGO': {
        base: '2.50%',
        max: '2.50%',
    },
    'BCH': {
        base: '3.00%',
        max: '3.00%',
    },
};


export const ratesInt = {
    ...ratesUS,
    'USDT': {
        base: blockfiTetherAPY,
        max: maxBlockfiTetherAPY,
    },
    'ADA': {
        base: '10.00%',
        max: '10.00%',
    },
    'ATOM': {
        base: '7.00%',
        max: '7.00%',
    },
    'AVAX': {
        base: '5.00%',
        max: '5.00%',
    },
    'DOT': {
        base: '15.00%',
        max: '15.00%',
    },
    'FIL': {
        base: '2.00%',
        max: '2.00%',
    },
    'SOL': {
        base: '5.00%',
        max: '5.00%',
    },
    'MATIC': {
        base: '5.00%',
        max: '5.00%',
    },
    'BNB': {
        base: '3.00%',
        max: '3.00%',
    },
    'LUNA': {
        base: '6.00%',
        max: '6.00%',
    },
    'FTT': {
        base: '3.00%',
        max: '3.00%',
    },
    'NEAR': {
        base: '7.00%',
        max: '7.00%',
    },
    'TRX': {
        base: '6.00%',
        max: '6.00%',
    },
    'FTM': {
        base: '3.00%',
        max: '3.00%',
    },
    'MANA': {
        base: '3.00%',
        max: '3.00%',
    },
    'AXS': {
        base: '3.00%',
        max: '3.00%',
    },
    'SAND': {
        base: '3.00%',
        max: '3.00%',
    },
    'EOS': {
        base: '3.00%',
        max: '3.00%',
    },
    'AAVE': {
        base: '3.00%',
        max: '3.00%',
    },
    'GALA': {
        base: '3.00%',
        max: '3.00%',
    },
    'RUNE': {
        base: '3.00%',
        max: '3.00%',
    },
    'SRM': {
        base: '3.00%',
        max: '3.00%',
    },
};
