import React from 'react';

export const nexoDateEffective = '4/27/24';
const lock12Months = ' - requires locking asset for 12 months';

const platinumInNexo = ' (APR) - requires maintaining a ratio over 10% for NEXO tokens vs rest of portfolio, and receiving interest in NEXO';
const lockPlatinumInNexo = ' (APR) - requires locking asset for 1 month, maintaining a ratio over 10% for NEXO tokens vs rest of portfolio, and receiving interest in NEXO';
const lock3PlatinumInNexo = ' (APR) - requires locking asset for 3 months, maintaining a ratio over 10% for NEXO tokens vs rest of portfolio, and receiving interest in NEXO';
const lock12PlatinumInNexo = ' (APR) - requires locking asset for 12 months, maintaining a ratio over 10% for NEXO tokens vs rest of portfolio, and receiving interest in NEXO';

const nexoTiered = <div>4% on first $50k <br />1% for over $50k</div>;

const bitcoinBaseRate = <div>3% on first $25k <br />1.5% for over $25k</div>;
const linkBaseRate = bitcoinBaseRate;

const ethereumBaseRate = <div>4% on first $100k <br />1.5% for over $100k</div>;

const paxGoldBaseRate = <div>3% on first $25k <br />1% for over $25k</div>;

export const ratesInt = {
    USDT: { base: '9.00%', max: '16.00%' + lock3PlatinumInNexo },
    USDC: { base: '8.00%', max: '14.00%' + lock3PlatinumInNexo },
    USDP: { base: '8.00%', max: '14.00%' + lock3PlatinumInNexo },
    TUSD: { base: '8.00%', max: '14.00%' + lock3PlatinumInNexo },
    DAI: { base: '8.00%', max: '14.00%' + lock3PlatinumInNexo },
    USDx: { base: '5.00%', max: '15.00%' + lock12PlatinumInNexo },
    EURx: { base: '5.00%', max: '15.00%' + lock12PlatinumInNexo },
    GBPx: { base: '5.00%', max: '15.00%' + lock12PlatinumInNexo },
    BTC: { base: bitcoinBaseRate, max: '7% on first $4M' + lockPlatinumInNexo },
    ETH: { base: ethereumBaseRate, max: '8% on first $4M' + lockPlatinumInNexo },
    NEXO: { base: '4.00%', max: '12.00%' + lock12Months },
    DOT: { base: '10.00%', max: '15.00%' + lockPlatinumInNexo },
    AVAX: { base: '3.00%', max: '8.00%' + lockPlatinumInNexo },
    SOL: { base: '4.00%', max: '8.00%' + lockPlatinumInNexo },
    ADA: { base: '4.00%', max: '8.00%' + lockPlatinumInNexo },
    XRP: { base: nexoTiered, max: '12% on first $10M' + lockPlatinumInNexo },
    BCH: { base: '4.00%', max: '11.00%' + lockPlatinumInNexo },
    LTC: { base: '4.00%', max: '8.00%' + lockPlatinumInNexo },
    BNB: { base: '4.00%', max: '8.00%' + lockPlatinumInNexo },
    EOS: { base: '4.00%', max: '8.00%' + lockPlatinumInNexo },
    XLM: { base: '4.00%', max: '8.00%' + lockPlatinumInNexo },
    LINK: { base: linkBaseRate, max: '7% on first $4M' + lockPlatinumInNexo },
    TRX: { base: '4.00%', max: '11.00%' + lockPlatinumInNexo },
    PAXG: { base: paxGoldBaseRate, max: '7% on first $4M' + lockPlatinumInNexo },
    AAVE: { base: '0.25%', max: '4.00%' + lockPlatinumInNexo },
    DOGE: { base: '0.00%', max: '3.00%' + platinumInNexo },
    MATIC: { base: '1.00%', max: '7.00%' + lockPlatinumInNexo },
    AXS: { base: '16.00%', max: '30.00%' + lockPlatinumInNexo },
    FTM: { base: '2.00%', max: '7.00%' + lockPlatinumInNexo },
    ATOM: { base: '6.00%', max: '11.00%' + lockPlatinumInNexo },
    KSM: { base: '7.00%', max: '12.00%' + lockPlatinumInNexo },
    NEAR: { base: '5.00%', max: '10.00%' + lockPlatinumInNexo },
    APE: { base: '4.00%', max: '9.00%' + lockPlatinumInNexo },
    ARB: { base: '1.00%', max: '6.00%' + platinumInNexo },
    OSMO: { base: '0.00%', max: '5.50%' + lock3PlatinumInNexo },
    GRT: { base: '0.00%', max: '5.00%' + lock3PlatinumInNexo },
    INJ: { base: '1.00%', max: '9.00%' + lockPlatinumInNexo },
    TIA: { base: '1.00%', max: '9.00%' + lock3PlatinumInNexo },
    ENA: { base: '7.00%', max: '13.00%' + lockPlatinumInNexo },
};
